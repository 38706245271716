import { getCurrentInstance ,reactive} from 'vue';
import { useStore } from "vuex";
import { useMQTT } from 'mqtt-vue-hook'
import { MQTTCommand, MQTTTempCommand } from '../constants';


export default function useMqttControl() {  
   
  const store = useStore();
  const { mqtt,mobile ,logger} = getCurrentInstance().appContext.config.globalProperties;
  const mqttHook = mqtt.getMqttHook();

  const registMcsEvent = (topic,message) => {
    let data = JSON.parse(message.toString())
    // console.log(`data : ${message.toString()}`)
    switch(data.cmd){
      case MQTTCommand.SettingMode:
        store.dispatch("home/changeDeviceSettingMode",data)
        break;
      case MQTTCommand.StateChanged:
      case MQTTCommand.Control:
        store.dispatch("home/changeDeviceState",data)
        break;
      case MQTTCommand.FirmwareUpdateStarted:
      case MQTTCommand.FirmwareUpdateFinished:
        store.dispatch("home/changeControllerFirmwareUpdateState",data)
        break;         
      case MQTTCommand.Version:
        store.dispatch("home/updateControllerFirmwareVersion",data)
        break;     
      case MQTTCommand.Ping:
        store.dispatch("home/updateCheckControllers", { ...data, value: 1 })
        break;
    }
  }
  const registZoneEvent = (topic,message) => {
    const arrTopics = topic.split('/')
    const zoneId = arrTopics[3]
    const payload = JSON.parse(message.toString())
    console.log(`[mqtt] subscribe received => zoneId : ${zoneId},payload : ${message.toString()}`)

    const zone = store.getters['home/getZones'].find(z => z._id === parseInt(zoneId))
    if(zone){
      console.log(`[mqtt] subscribe received => zone : ${zone.name},payload : ${message.toString()}`)
      if(payload.isEditMode){
        mobile.showSnackBar(`영역[${zone.name}]이 설정 상태로 변경되었습니다.`)
      }else{
        mobile.showSnackBar(`영역[${zone.name}]이 설정이 완료되었습니다.`)
      }
    }            

    store.dispatch("home/changeZoneEditMode",{...payload,zoneId:parseInt(zoneId)})    
  }
  const registStatEvent = (topic,message) => {
    try {
      const splittedTopics = topic.split("/");
      if (splittedTopics.length < 4) {
        return;
      }
  
      const controllerId = splittedTopics[2];
      const command = splittedTopics[3];
      if (command == "TIMERGROUPENABLE") {
        store.dispatch("home/updateCheckControllers", { serial: controllerId, value: 1 });
        return;
      }

      const data = {
        command: command,
        controllerId: controllerId,
        value: JSON.parse(message)
      };

      store.dispatch("home/chanageTempDeviceState",data);
    } catch(e) {
      console.log(e);
    }
  }
  const registCmndEvent = (topic,message) => {
    console.log(topic,message)
  }

  const subscribe = async (topics) =>{
    return new Promise(async (resolve, reject) => {
      try {
        await mqtt.subscribe(topics);
        for (const topic of topics){          
          await mqttHook.unRegisterEvent(topic);
          await mqttHook.registerEvent(topic, (topic,message) =>{
            // console.log(`[mqtt] subscribe received => topic : ${topic},message : ${message.toString()}`)
            if (topic.includes('mcs')) {     
              registMcsEvent(topic, message);
            } else if (topic.includes('zone')) {                     
              registZoneEvent(topic, message);
            } else if (topic.includes('stat')) {                     
              registStatEvent(topic, message);
            }
          });
        };

        for (const topic of topics){
          if (topic.includes('mcs')) {
            const splittedTopic = topic.split("/");
            if (splittedTopic.length === 6) {
              onPublishController(splittedTopic[3], {
                serial: splittedTopic[3],
                cmd: "q",
                type: 1
              });
            }
          }
        };

        resolve();
      } catch (e) {
        reject();
      }
    });
  }
  const unSubscribe = async (topics) =>{
    // console.log('useMqttControl.unSubscribe => topics : ',topics)
    // topics.forEach(async (topic) => {
    //   await mqtt.unSubscribe(topic)
    // })    

    console.log('unSubscribe => topics : ',topics)
    for(const topic of topics) {
      await mqtt.unSubscribe(topic)
    }
    return;
  }
  
  const onPublish = ((controllerId,deviceId,payload) =>{              
    console.log(`useMqttControl.onPublish => controllerId : ${controllerId},deviceId : ${deviceId},payload : ${payload}`)
    mqtt.publish(`${process.env.VUE_APP_MQTT_DEFAULT_TOPIC_PREFIX}/${process.env.VUE_APP_MQTT_MCS_TOPIC_PREFIX}/${controllerId}/${process.env.VUE_APP_MQTT_PUBLISH_TOPIC_SIRFIX}`, payload);
    store.dispatch("home/updateGroupDeviceState",{...payload,deviceId:deviceId}) 
  })
  const onPublishZone = ((zoneId,payload) =>{              
    console.log(`useMqttControl.onPublishZone => zoneId : ${zoneId},payload : ${JSON.stringify(payload)}`)
    mqtt.publish(`${process.env.VUE_APP_MQTT_DEFAULT_TOPIC_PREFIX}/${process.env.VUE_APP_MQTT_ZONE_TOPIC_PREFIX}/${zoneId}/${process.env.VUE_APP_MQTT_PUBLISH_TOPIC_SIRFIX}`, payload);
    // store.dispatch("home/updateGroupDeviceState",{...payload,deviceId:deviceId}) 
  })

  const onPublishFirmware = (controllerId, payload)=> {    
    mqtt.publish(`${process.env.VUE_APP_MQTT_DEFAULT_TOPIC_PREFIX}/${process.env.VUE_APP_MQTT_MCS_TOPIC_PREFIX}/${controllerId}/${process.env.VUE_APP_MQTT_PUBLISH_TOPIC_SIRFIX}`, payload);
  }

  const onPublishController = (controllerId, payload)=> {    
    mqtt.publish(`${process.env.VUE_APP_MQTT_DEFAULT_TOPIC_PREFIX}/${process.env.VUE_APP_MQTT_MCS_TOPIC_PREFIX}/${controllerId}/${process.env.VUE_APP_MQTT_PUBLISH_TOPIC_SIRFIX}`, payload);
  }

  const onPublishTemp = (controllerId, command, payload)=> {
    if (command === MQTTTempCommand.AwayModeSet) {
      payload = payload == 0 ? '1' : '0';
    }
    console.log(`${process.env.VUE_APP_MQTT_TEPM_TOPIC_PREFIX}${controllerId}/${command}`, payload);
    mqtt.publishAuth(`${process.env.VUE_APP_MQTT_TEPM_TOPIC_PREFIX}/${controllerId}/${command}`, payload);
  }

  const registerEvent = (eventName,callback) =>{        
    mqttHook.registerEvent(eventName,callback)
  }

  const unRegisterEvent = (eventName) =>{
    mqttHook.unRegisterEvent(eventName)
  }

  const isConnected = () => {
    return mqttHook.isConnected()
  }
  const clearEvent = async() => {
    return await mqttHook.clearEvent()
  }
  const getMqttHook = () => {
    return mqttHook;
  }
  return {subscribe,unSubscribe,onPublish,onPublishZone,onPublishFirmware,onPublishController,onPublishTemp,registerEvent,unRegisterEvent,isConnected,clearEvent,getMqttHook}  
}
