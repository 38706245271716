<template>
  <PopDialog2
    title="방 정보 수정"
    :visible="props.visible"
    :okCallback="onOkClick"
    :cancelCallback="onCancelClick"
  >
    <div class="md-layout">
      <div class="md-layout-item md-size-50 mx-auto text-center mb-4">
        <!-- <h2 class="title mt-4">{{props.roomName}} 보일러</h2> -->
        <h2 class="title mt-4 mb-4">보일러 패턴</h2>

        <hr class="mb-3" />

        <div class="text-start mb-2" style="font-size: 1rem">P1</div>
        <div class="progress mb-1">
          <div class="progress-bar bg-warning" role="progressbar" style="width: 33%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">10분 켜짐</div>
          <div class="progress-bar bg-secondary" role="progressbar" style="width: 67%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">20분 꺼짐</div>
        </div>

        <hr class="mb-3" />

        <div class="text-start mb-2" style="font-size: 1rem">P2</div>
        <div class="progress mb-1">
          <div class="progress-bar bg-warning" role="progressbar" style="width: 25%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">10분 켜짐</div>
          <div class="progress-bar bg-secondary" role="progressbar" style="width: 75%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">30분 꺼짐</div>
        </div>

        <hr class="mb-3" />

        <div class="text-start mb-2" style="font-size: 1rem">P3</div>
        <div class="progress mb-1">
          <div class="progress-bar bg-warning" role="progressbar" style="width: 20%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">10분 켜짐</div>
          <div class="progress-bar bg-secondary" role="progressbar" style="width: 80%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">40분 꺼짐</div>
        </div>

        <hr class="mb-3" />

        <div class="text-start mb-2" style="font-size: 1rem">P4</div>
        <div class="progress mb-1">
          <div class="progress-bar bg-warning" role="progressbar" style="width: 17%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">10분 켜짐</div>
          <div class="progress-bar bg-secondary" role="progressbar" style="width: 83%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">50분 꺼짐</div>
        </div>

        <hr class="mb-3" />
        
        <div class="row mb-2">
          <div class="col-6 text-start" style="font-size: 1rem">P5</div>
          <div class="col text-end text-muted" style="font-size: 0.8rem" @click="onP5ExpandClick">설정&nbsp;&nbsp;
            <i class="fa fa-chevron-down" style="font-size: 0.8rem" v-if="isP5Expanded" />
            <i class="fa fa-chevron-right" style="font-size: 0.8rem" v-else />
          </div>
        </div>
        <div class="progress mb-3">
          <div class="progress-bar bg-warning" role="progressbar" :style="`width: ${startTimePercent}%`" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{{ startTime }}분 켜짐</div>
          <div class="progress-bar bg-secondary" role="progressbar" :style="`width: ${endTimePercent}%`" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{{ endTime }}분 꺼짐</div>
        </div>
        <div class="card" v-if="isP5Expanded">
          <div class="card-body">
              <Slider
                name="켜지는 시간"
                id="start"
                :default="startTime"
                :nameWidth="3"
                :min="5"
                :max="30"
                :step="5"
                @onSliderChanged="onStartTimeChanged"
              />
              <Slider
                name="꺼지는 시간"
                id="end"
                :default="endTime"
                :nameWidth="3"
                :min="10"
                :max="60"
                :step="5"
                @onSliderChanged="onEndTimeChanged"
              />
          </div>
        </div>
      </div>
    </div>
  </PopDialog2>
</template>

<script setup>
import { ref, defineProps, computed, getCurrentInstance, reactive } from 'vue';
import { useStore } from 'vuex';
import { MQTTTempCommand } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';
import PopDialog2 from '@/common/components/popup/PopDialog2.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const store = useStore();
const props = defineProps([
  'visible',
  'okCallback',
  'cancelCallback',
  'roomName',
  'roomId',
  'handlePowerClick'
]);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});
const boilerDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 2,
  );
});

const isP5Expanded = ref(false);
const startTime = ref(boilerDevices.value.length > 0 ? boilerDevices.value[0].item.customPattern.on : 10);
const endTime = ref(boilerDevices.value.length > 0 ? boilerDevices.value[0].item.customPattern.off : 10);

const startTimePercent = computed(() => {
  const startValue = parseInt(startTime.value);
  const endValue = parseInt(endTime.value);
  return startValue / (startValue + endValue) * 100;
});
const endTimePercent = computed(() => {
  const startValue = parseInt(startTime.value);
  const endValue = parseInt(endTime.value);
  return endValue / (startValue + endValue) * 100;
});

// const zone = computed(() => {
//   if (store.state.home.zones.length > 0) {
//     return store.getters['home/getCurrentZone'];
//   } else return null;
// });
// const group = computed(() => {
//   return zone.value.groups.find((g) => g._id === props.roomId);
// });
// const devices = computed(() => {
//   return group.value ? group.value.devices : undefined;
// });

// const boilerDevices = computed(() => {
//   return devices.value.filter(
//     (d) => d.type === 2,
//   );
// });
const currentTemp = computed(() => {
  return 18;
});
const targetTemp = computed(() => {
  // return boilerDevices.value.length > 0 && boilerDevices.value[0].item.temperature ? boilerDevices.value[0].item.temperature : 10;
  return 22;
});

const deviceState = (type) => {
  // return devices.value
  //   .filter((d) => d.type === type)
  //   .some((d) => d.item.onoff === PowerState.On);
  return true;
};

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};

const onP5ExpandClick = () => {
  isP5Expanded.value = !isP5Expanded.value;
}

const onStartTimeChanged = (e) => {
  startTime.value = e;
  boilerDevices.value.forEach((device) => {
    mqttControl.onPublishTemp(device.controllerId, MQTTTempCommand.TimeOnCycleSet, e);
  });
}

const onEndTimeChanged = (e) => {
  endTime.value = e;
  boilerDevices.value.forEach((device) => {
    mqttControl.onPublishTemp(device.controllerId, MQTTTempCommand.TimeOffCycleSet, e);
  });
}
</script>
