<template>
  <PopDialog
    title="공유 설정 변경"
    :visible="props.visible"
    :okCallback="onOkClick"
    :cancelCallback="props.cancelCallback"
  >
    <p v-if="sharedZones.length > 0">
      아래의 집 목록 중 공유할 집의 체크박스를 선택하여 공유 여부를 변경하세요.
    </p>
    <p v-else>
      공유할 집 목록이 없습니다.
    </p>
    <Card v-if="sharedZones.length > 0">
      <div class="list-group list-group-flush">
        <MemberZoneItem
          v-for="(item, index) in sharedZones"
          :key="index"
          :user="user"
          :zone="item"
          :handleShareChange="onShareChange"
        />
      </div>
    </Card>

    <div class="d-flex mt-3">
      <div class="flex-fill" />
      <button type="button" class="btn btn-default" @click="onCancelShareClick">
        공유 취소
      </button>
    </div>
    
  </PopDialog>
</template>

<script setup>
import { ref, getCurrentInstance, defineProps, computed, reactive } from 'vue';
import Axios, { AuthApi } from '@/api';
import { useStore } from 'vuex';
import PopDialog from '@/common/components/popup/PopDialog.vue';
import MemberZoneItem from './MemberZoneItem.vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const props = defineProps([
  'visible',
  'okCallback',
  'cancelCallback',
  'user',
  'handleSharedZonesUpdated',
]);
const store = useStore();
const getters = computed(() => store.getters);
const profile = computed(() => getters.value.account);
const zones = computed(() => store.getters['home/getZones']);
const ownedZones = computed(() =>
  zones.value.filter((item) => item.ownerId === profile.value._id),
);
const sharedZones = computed(() => {
  return ownedZones.value.map((zone) => ({
    ...zone,
    shared: props.user.zones.find((item) => item._id === zone._id) !== undefined,
  }))
});

const onShareChange = (zoneId) => {

  const targetId = sharedZones.value.findIndex((item) => item._id === zoneId);
  console.log('targetId:',targetId)
  if (targetId !== undefined) {
    sharedZones.value[targetId].shared = !sharedZones.value[targetId].shared;
  }
};

const onOkClick = () => {
  // const zones = sharedZones.value
  //   .filter((item) => item.shared)
  //   .map((item) => item._id);

  const zones = sharedZones.value.map(z => {return {zoneId:z._id,shared:z.shared}})
  requestUpdateSharedZones({
    id: props.user._id,
    zones,
  });
};

const requestUpdateSharedZones = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.post(AuthApi.SHARE_ZONES, params, 'auth');
      if (result.resultCode === '0000') {
        if (props.okCallback) {
          props.okCallback(params);
          resolve(result);
        }
      } else {
        emitter.emit('showPopAlert', {
          description: result.resultMessage,
        });
        reject(result.resultMessage);
      }
    } catch (error) {
      emitter.emit('showPopAlert', {
        description: '공유 설정 변경에 실패했습니다.',
      });
      reject(error);
    }
  });
};

const requestCancelShare = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.post(AuthApi.CANCEL_SHARE, { id }, 'auth');
      if (result.resultCode === '0000') {
        if (props.okCallback) {
          props.okCallback();
          emitter.emit('showPopAlert', {
            description: '공유가 취소되었습니다.',
          });
        }
        resolve(result);
      } else {
        emitter.emit('showPopAlert', {
          description: result.resultMessage,
        });
        reject(result.resultMessage);
      }
    } catch (error) {
      emitter.emit('showPopAlert', {
        description: '공유 설정 변경에 실패했습니다.',
      });
      reject(error);
    }
  });
};

const onCancelShareClick = () => {
  emitter.emit('showPopConfirm', {
    description: '해당 구성원에 대한 공유를 취소하시겠습니까?',
    okCallback: async () => {
      try {
        const zones = sharedZones.value.map(z => ({ zoneId: z._id, shared: false }))
        await requestUpdateSharedZones({
          id: props.user._id,
          zones,
        });
        await requestCancelShare(props.user._id);
      } catch(e) {
        console.log(e)
      }
    }
  });
};
</script>
