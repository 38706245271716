<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">온도 조절기 설정</h4>
    <div class="row">
      <div class="col-xl-6">
        <div class="text-muted fs-12px mb-2"><b>온도조절기</b></div>
        <div class="list-group mb-3">
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onSettingTempRegistClick">
            <div class="flex-fill">
              <div class="fw-600">온도조절기 등록</div>
              <div class="fs-12px text-muted">
                온도조절기를 등록할 수 있습니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onSettingTempListClick">
            <div class="flex-fill">
              <div class="fw-600">온도조절기 관리</div>
              <div class="fs-12px text-muted">
                온도조절기를 삭제할 수 있습니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const router = useRouter();

const onSettingTempRegistClick = () => router.push('/settings/temp/regist');
const onSettingTempListClick = () => router.push('/settings/temp/list');
</script>
