import { getCurrentInstance, computed } from 'vue';
import { useStore } from 'vuex';
import Axios, { CtrlApi, ZoneApi } from '@/api';
import { DeviceDefault } from '@/common/constants';

export default function useMobile() {
  const { emitter, mobile, logger } =
    getCurrentInstance().appContext.config.globalProperties;
  const store = useStore();
  const getters = computed(() => store.getters);
  const controllers = computed(
    () => getters.value['home/getLowVersionControllers'],
  );

  const ap_wifi = {
    ssid: DeviceDefault.AP_SSID,
    password: DeviceDefault.AP_PWD,
  };

  const searchWifiList = () => {
    return new Promise((resolve, reject) => {
      let timerId = undefined;

      emitter.on('onSearchResult', (payload) => {
        clearTimeout(timerId);
        emitter.emit('hideLoading');
        emitter.off('onSearchResult');
        if (payload.data) {
          const wifis = JSON.parse(decodeURIComponent(window.atob(payload.data)));
          const wifiItems = wifis.filter(item => item.ssid?.length > 0).sort((a, b) => {
            if (a.level > b.level) {
              return -1;
            } else if (a.level < b.level) {
              return 1;
            } else {
              return 0;
            }
          });
          resolve(wifiItems);
        } else {
          resolve([]);
        }
      });
      
      emitter.emit('showLoading');

      timerId = setTimeout(() => {
        reject('wifi 목록을 조회할 수 없습니다.\n다시 시도해주세요.');
      }, 10000);

      const ret = mobile.searchWifi(
        JSON.stringify({ prefix: '', callback: 'onSearchResult' }),
      );
      if (ret === false) {
        clearTimeout(timerId);
        emitter.emit('hideLoading');
        reject("wifi 검색에 실패했습니다.");
      }
    });
  };

  const getWifiInfo = () => {
    // show loading
    // add event
    return new Promise((resolve, reject) => {
      emitter.on('onGetWifiResult', (payload) => {
        emitter.emit('hideLoading');
        emitter.off('onGetWifiResult');

        logger.log('TransferReceivePage.getWifiInfo => payload : ' + payload);

        let currentWifi = undefined;
        if (payload.data) {
          currentWifi = JSON.parse(
            decodeURIComponent(window.atob(payload.data)),
          );
          logger.log(
            'TransferReceivePage.getWifiInfo => currentWifi : ' +
              JSON.stringify(currentWifi),
          );

          if (!currentWifi) {
            reject('검색 결과가 없습니다.');
            return;
          }

          resolve(currentWifi);
        }
      });

      emitter.emit('showLoading');
      const ret = mobile.getWifiInfo(JSON.stringify({ callback: 'onGetWifiResult' }));
      if (ret === false) {
        emitter.emit('hideLoading');
      }
    });
  };

  // 컨트롤러 연결 :
  const connectWifi = (params) => {
    // add event
    return new Promise((resolve, reject) => {
      emitter.on('onConnectResult', (payload) => {
        emitter.emit('hideLoading');
        emitter.off('onConnectResult');

        logger.log(
          `TransferReceivePage.connectWifi => onConnectResult : payload : ${JSON.stringify(payload)} `,
        );

        if (payload.result) {
          let decoded = JSON.parse(
            decodeURIComponent(window.atob(payload.data)),
          );
          logger.log(
            `TransferReceivePage.connectWifi => onConnectResult : decoded : ${JSON.stringify(
              decoded,
            )}`,
          );

          if (decoded) {
            if (decoded.message) {
              reject(decoded.message);
              return;
            }
            // connectedController.value = decoded.device
            let arr = decoded.device.serial_no.split('_');
            resolve({
              model: arr[0],
              serial_no: decoded.device.serial_no,
              version: decoded.device.version,
            });
            return;
          }
          reject('연결에 실패하였습니다.');
          return;
        }

        reject('연결에 실패하였습니다.');
      });

      emitter.emit('showLoading');
      mobile.connectWifi(
        JSON.stringify(params ? {
          ...params,
          callback: 'onConnectResult'
         } : {
          ssid: ap_wifi.ssid,
          password: ap_wifi.password,
          bssid: ap_wifi.bssid || undefined,
          callback: 'onConnectResult',
        }),
      );
    });
  };

  const connectTest = (params) => {
    return new Promise((resolve, reject) => {
      emitter.on('onConnectTestResult', (payload) => {
        emitter.off('onConnectTestResult');

        if (payload.result) {
          resolve(true);
          return;
        }

        reject('연결에 실패하였습니다.');
      });

      mobile.connectTest(
        JSON.stringify({
          ...params,
          callback: 'onConnectTestResult'
        }))
    });
  };

  // 컨트롤러 연결 :
  const connectTempWifi = (params) => {
    // add event
    return new Promise((resolve, reject) => {
      emitter.on('onConnectTempResult', (payload) => {
        emitter.emit('hideLoading');
        emitter.off('onConnectTempResult');

        logger.log(
          `TransferReceivePage.connectWifi => onConnectTempResult : payload : ${JSON.stringify(payload)} `,
        );

        if (payload.result) {
          resolve(true);
          return;
        }

        reject('연결에 실패하였습니다.');
      });

      console.log(JSON.stringify(params))

      emitter.emit('showLoading');
      mobile.connectTempWifi(
        JSON.stringify(params ? {
          ...params,
          callback: 'onConnectTempResult'
         } : {
          ssid: ap_wifi.ssid,
          password: ap_wifi.password,
          bssid: ap_wifi.bssid || undefined,
          callback: 'onConnectTempResult',
        }),
      );
    });
  };

  // 컨트롤러 설정 :
  const settingController = (settingParams, controllerId) => {
    return new Promise((resolve, reject) => {
      emitter.on('onSettingResult', (payload) => {
        emitter.emit('hideLoading');
        emitter.off('onSettingResult');

        logger.log(
          `TransferReceivePage.settingController => onSettingResult : payload : ${JSON.stringify(
            payload,
          )}`,
        );
        // if (payload.result) {
          mobile.disConnectWifi(JSON.stringify(ap_wifi));
          resolve();
          return;
        // } else {
        //   let decoded = JSON.parse(
        //     decodeURIComponent(window.atob(payload.data)),
        //   );
        //   logger.log(
        //     `TransferReceivePage.settingController => onSettingResult : decoded : ${JSON.stringify(
        //       decoded,
        //     )} `,
        //   );
        //   if (decoded) {
        //     if (decoded.message) {
        //       reject(decoded.message);
        //       return;
        //     }
        //   }
        //   reject('설정에 실패하였습니다.');
        // }
      });

      let params = { ...settingParams, callback: 'onSettingResult' };
      const targetController = controllers.value.find(
        (item) => item._id === controllerId,
      );
      if (targetController) {
        params = {
          ...params,
          firmware_version: targetController.lastVersion,
          firmware_url: targetController.downloadUrl,
        };
      }

      logger.log(
        `TransferReceivePage.settingController => onSettingResult : params : ${JSON.stringify(
          params,
        )} `,
      );

      emitter.emit('showLoading');
      mobile.settingDevice(JSON.stringify(params));
    });
  };

  // 컨트롤러 검색 :
  const getControllerInfo = (controller) => {
    return new Promise(async (resolve, reject) => {
      try {
        logger.log(
          `TransferReceivePage.getControllerInfo => controller : ${JSON.stringify(
            controller,
          )}`,
        );

        // check controllerId
        const exControllerResult = await Axios.get(
          CtrlApi.CTRL_INFO.replace(':id', controller._id),
        );
        // if (exControllerResult.resultCode === '0000') {
          logger.log(
            `TransferReceivePage.getControllerInfo => exControllerResult : ${JSON.stringify(
              exControllerResult,
            )}`,
          );
          resolve(exControllerResult);
          return;
        // }

        // resolve(undefined);
      } catch (e) {
        console.log(e);
        logger.log(e);
        resolve('컨트롤러 추가중 오류가 발생하였습니다.');
      }
    });
  };

  const disConnectWifi = (force = false) => {
    if (force) {
      mobile.disConnectWifi(JSON.stringify({force: "true"}));
    } else {
      mobile.disConnectWifi(JSON.stringify(ap_wifi));
    }
  }

  // 컨트롤러 이관 :
  const requestTransferController = (controller, userId, srcZoneId = undefined, tarZoneId = undefined) => {
    return new Promise(async (resolve, reject) => {
      try {
        logger.log(
          `TransferReceivePage.requestTransferController => controller : ${JSON.stringify(
            controller,
          )}`,
        );

        const result = await Axios.patch(
          CtrlApi.TRANSFER_CTRL.replace(':id', controller._id), { 
            id: userId,
            srcZoneId: srcZoneId,
            tarZoneId: tarZoneId 
          },
        );
        if (result.resultCode === '0000') {
          logger.log(
            `TransferReceivePage.requestTransferController => result : ${JSON.stringify(
              result,
            )}`,
          );
          resolve(result);
          return;
        }

        // reject(result.resultMessage);
        resolve(result);
      } catch (e) {
        console.log(e);
        logger.log(e);
        // reject('컨트롤러 이관 중 오류가 발생하였습니다.');
        resolve('컨트롤러 이관 중 오류가 발생하였습니다.');
      }
    });
  };

  // 컨트롤러 등록 :
  const requestRegistController = (controller, zoneId) => {
    return new Promise(async (resolve, reject) => {
      try {
        logger.log(
          `TransferReceivePage.requestRegistController => controller : ${JSON.stringify(
            controller,
          )}`,
        );

        const result = await Axios.post(
          ZoneApi.REGIST_ZONE_CTRLS.replace(':id', zoneId),
          controller,
        );
        if (result.resultCode === '0000') {
          logger.log(
            `TransferReceivePage.requestRegistController => result : ${JSON.stringify(
              result,
            )}`,
          );
          resolve(result);
          return;
        }

        // reject(result.resultMessage);
        resolve(result);
      } catch (e) {
        console.log(JSON.stringify(e));
        logger.log(e);
        // reject('컨트롤러 추가중 오류가 발생하였습니다.');
        resolve('컨트롤러 추가중 오류가 발생하였습니다.');
      }
    });
  };

  const listenChangedNetworkInfo = () =>{
    return new Promise(async (resolve, reject) => {
      emitter.on('onChangedNetworkInfo', (payload) => {  
        logger.log('listenChangedNetworkInfo.onChangedNetworkInfo => payload : ', payload)
        if (payload.data) {
          logger.log(`listenChangedNetworkInfo.onChangedNetworkInfo => payload : ${JSON.stringify(payload)}`);
          let decoded = JSON.parse(decodeURIComponent(window.atob(payload.data)));
          logger.log(`listenChangedNetworkInfo.onChangedNetworkInfo => decoded : ${JSON.stringify(decoded)}`);
          if(decoded.type != 'none'){
            emitter.emit('hideLoading');
            emitter.off('onChangedNetworkInfo');
          }
          resolve("");
        }    
      });

      emitter.emit('showLoading');
      setTimeout(() => {
        emitter.emit('hideLoading');
        emitter.off('onChangedNetworkInfo');
        reject("네트워크 변경에 실패했습니다.");
      }, 10000);
    });
  };
  
  // 컨트롤러 검색 :
  const readQRCode = () => {
    return new Promise((resolve, reject) => {
      emitter.on('onReadQRCodeResult', (payload) => {
        emitter.emit('hideLoading');
        emitter.off('onReadQRCodeResult');

        logger.log(
          `TransferReceivePage.connectWifi => readQRCode : payload : ${JSON.stringify(payload)} `,
        );

        if (payload.result) {
          resolve(payload.data);
          return;
        }

        reject('QR코드 인식에 실패하였습니다.');
      });

      emitter.emit('showLoading');
      mobile.readQRCode(
        JSON.stringify({
          callback: 'onReadQRCodeResult',
        }),
      );
    });
  };

  // 컨트롤러 검색 :
  const showRegistView = async (visible) => {
    return new Promise((resolve, reject) => {
      emitter.on('onShowRegistViewResult', (payload) => {
        emitter.emit('hideLoading');
        emitter.off('onShowRegistViewResult');

        // mobile.disConnectWifi(true);
        console.log("onShowRegistViewResult");
        console.log(JSON.stringify(payload));
        if (payload.result) {
          resolve(true);
          return;
        }

        resolve(false);
        //reject('온도조절기 등록에 실패했습니다.');
      });

      emitter.emit('showLoading');
      mobile.showRegistView(
        JSON.stringify({
          callback: 'onShowRegistViewResult',
          visible: visible
        }),
      );
    });
  };

  // 로컬 데이터 조회 :
  const getLocalStorage = async (key) => {
    return new Promise((resolve, reject) => {
      const callbackName = `onGetLocalStorageResult_${key}`;

      const timerId = setTimeout(() => {
        emitter.emit('hideLoading');
        reject('단말기로부터 응답이 없습니다.');
      }, 1000);

      emitter.on(callbackName, (payload) => {
        emitter.emit('hideLoading');
        emitter.off(callbackName);

        // mobile.disConnectWifi(true);
        console.log(callbackName, JSON.stringify(payload));
        if (payload.result) {
          const value = JSON.parse(decodeURIComponent(window.atob(payload.data)));
          console.log("value:", value);
          clearTimeout(timerId);
          resolve(value);
          return;
        }

        clearTimeout(timerId);
        reject('로컬 데이터 조회에 실패했습니다.');
      });

      emitter.emit('showLoading');
      mobile.getLocalStorage(
        JSON.stringify({
          callback: callbackName,
          key: key
        }),
      );
    });
  };

  // 로컬 데이터 저장 :
  const setLocalStorage = async (key, value) => {
    return new Promise((resolve, reject) => {
      const callbackName = `onSetLocalStorageResult_${key}`;
      
      const timerId = setTimeout(() => {
        emitter.emit('hideLoading');
        reject('단말기로부터 응답이 없습니다.');
      }, 1000);

      emitter.on(callbackName, (payload) => {
        emitter.emit('hideLoading');
        emitter.off(callbackName);

        // mobile.disConnectWifi(true);
        console.log(callbackName, JSON.stringify(payload));
        if (payload.result) {
          clearTimeout(timerId);
          resolve(true);
          return;
        }

        clearTimeout(timerId);
        reject('로컬 데이터 저장에 실패했습니다.');
      });

      emitter.emit('showLoading');
      mobile.setLocalStorage(
        JSON.stringify({
          callback: callbackName,
          key: key,
          value: value
        }),
      );
    });
  };

  return {
    searchWifiList,
    getWifiInfo,
    connectWifi,
    connectTest,
    connectTempWifi,
    settingController,
    getControllerInfo,
    requestTransferController,
    requestRegistController,
    listenChangedNetworkInfo,
    disConnectWifi,
    readQRCode,
    showRegistView,
    getLocalStorage,
    setLocalStorage
  };
}
