<template>
  <div id="listWidget" class="mb-4">
    <div>
      <h4 class="mb-3">전동커튼 확인</h4>
      <p class="mb-0">
        1. 등록된 온도 조절기를 선택해주세요.
      </p>
      <p class="mb-0">
        2. 등록된 온도 조절기가 없으면 온도 조절기를 먼저 등록해주세요.
      </p>
    </div>

    <hr class="mb-3" />
    
    <!-- <canvas id="canvas" /> -->

    <div class="row" v-if="tempControllers.length > 0">
      <div class="col-xl-6 mb-3">
        <div class="text-muted fs-12px mb-2"><b>온도 조절기 선택</b></div>
        <div class="list-group" style="border-radius: 1.2rem">
          <ListGroupItem
            style="border-radius: 1.2rem; margin-bottom: 4px;"
            v-for="(controller, index) in tempControllers"
            :key="controller._id"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`gridCheck${index}`"
                :value="controller._id"
                @change="onSelectControllerChange"
              />
              <label class="form-check-label" :for="`gridCheck${index}`">
                {{ controller.model }}
              </label>
            </div>
          </ListGroupItem>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-xl-6 mb-3">
        <div class="list-group" style="border-radius: 1.2rem">
          <div class="list-group-item" style="border-radius: 1.2rem;">
            <label class="text-gray-800">
              등록된 온도 조절기가 없습니다.
            </label>
            <button
              type="button"
              class="btn btn-outline-primary mb-1 me-1"
              style="min-width: 100%;"
              @click="onRegistClick"
            >
              온도 조절기 등록
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col" />
      <div class="col" />
      <div class="col">
        <button
          type="button"
          class="btn btn-primary btn-lg"
          style="width: 100%"
          @click="onNextClick"
        >
          다음
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  reactive,
} from 'vue';
import Axios, { DeviceApi } from '@/api';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useMobile from '@/common/hooks/useMobile';
import useMqttControl from '@/common/hooks/useMqttControl';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import { MQTTTempCommand, MQTTCurtainCommand } from '@/common/constants';
import { useMQTT } from 'mqtt-vue-hook';

const props = defineProps(['handleNextStep']);
const mqttControl = reactive(useMqttControl());
const { emitter, mobile } = getCurrentInstance().appContext.config.globalProperties;
const store = useStore();
const router = useRouter();
const selectedController = ref('');
const mqttHook = useMQTT()

const controllers = computed(() => {
  return store.getters['home/getControllers'];
});
const tempControllers = computed(() => {
  return controllers.value.filter(item => item._id.split("_").length < 2);
});

const onRegistClick = () => {
  router.replace("/settings/temp");
}

const onSelectControllerChange = (e) => {
  e.target.checked = true;
  selectedController.value = e.target.value;
}

const onNextClick = () => {
  const serial = selectedController.value;
  // props.handleNextStep({
  //   serialCode: selectedController.value
  // });
  // return;
  console.log(selectedController.value, serial)

  checkCurtainCount(serial, () => {
    props.handleNextStep({
      serialCode: selectedController.value
    });
  }, (message) => {
    emitter.emit('showPopAlert', {
      description: message,
    });
  })
}

const checkCurtainCount = async (serial, handleSuccess, handleFailure) => {
  emitter.emit('showLoading');

  const testTopic = `/inshow/${serial}/${MQTTTempCommand.IsScanCount}`;
  let intervalCount = 0;
  
  mqttHook.publish("cmnd" + testTopic, "1", 0);
  const intervalId = setInterval(async () => {
    if (intervalCount >= 5) {
      clearInterval(intervalId);

      await mqttHook.unRegisterEvent("stat" + testTopic);
      await mqttHook.unSubscribe("stat" + testTopic, undefined);      

      emitter.emit('hideLoading');

      if (handleFailure) {
        handleFailure("전동커튼 등록에 실패했습니다.");
      }
      return;
    }
    
    mqttHook.publish("cmnd" + testTopic, "1", 0);
    console.log("cmnd" + testTopic, "1")
    intervalCount++;
  }, 1000);

  mqttHook.subscribe(["stat" + testTopic], 0, undefined, async () => {
    await mqttHook.registerEvent("stat" + testTopic, async (topic,message) =>{
      console.log(topic, message);
      
      if (topic.includes(testTopic)) {
        clearInterval(intervalId);

        await mqttHook.unRegisterEvent(topic);
        await mqttHook.unSubscribe(topic, undefined);  

        emitter.emit('hideLoading');

        if (handleSuccess) {
          handleSuccess();
        }
      }
    });
  });
}
</script>