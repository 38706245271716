<template>
  <WizardConnectStep v-if="wizardStep === 1" :step="wizardStep" />
  <WizardSettingStep
    v-if="wizardStep === 2"
    :step="wizardStep"
    :controller="connectedController"
    :ssid="settingParams.ssid"
    :password="settingParams.pwd"
    :handleSsidChange="onSsidChange"
    :handlePasswordChange="onPasswordChange"
  />

  <div v-if="registMode">
    <WizardZoneSelectStep
      v-if="wizardStep === 3"
      :step="wizardStep"
      :zones="zones"
      :selectedZone="selectedZone"
      :handleChange="onSelectZoneChange"
    />
    <WizardTransferCompleteStep
      v-if="wizardStep === 4"
      :step="wizardStep"
      :controller="connectedController"
    />
  </div>
  <div v-else>
    <WizardTransferCompleteStep
      v-if="wizardStep === 3"
      :step="wizardStep"
      :controller="connectedController"
    />
  </div>

  <div id="footer" class="app-footer ms-0">
    <footer class="mt-auto">
      <div class="row">
        <div class="col" />
        <div class="col" />
        <div class="col">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            style="width: 100%"
            @click="onNextStepClick"
          >
            {{ wizardStep === 1 ? '연결' : wizardStep === 2 ? '등록' : '완료' }}
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useMobile from '@/common/hooks/useMobile';
import WizardConnectStep from './components/wizard/WizardConnectStep';
import WizardSettingStep from './components/wizard/WizardSettingStep';
import WizardZoneSelectStep from './components/wizard/WizardZoneSelectStep';
import WizardTransferCompleteStep from './components/wizard/WizardTransferCompleteStep';
import { SettingConfig } from '@/common/constants';

const store = useStore();
const router = useRouter();
const {
  getWifiInfo,
  connectWifi,
  settingController,
  getControllerInfo,
  requestTransferController,
  requestRegistController,
  disConnectWifi
} = useMobile();
const { emitter, mobile, logger } =
  getCurrentInstance().appContext.config.globalProperties;

const settingParams = ref({
  ssid: '',
  pwd: '',
  firmware_version: '',
  firmware_url: '',
  uuid: '',
  device_key: '',
  mqtt_url: process.env.VUE_APP_CTRL_MQTT_DOMAIN,
  mqtt_username: '',
  mqtt_password: '',
});

const connectedController = ref({
  _id: '',
  version: '',
  model: '',
});
const wizardStep = ref(1);
const account = computed(() => store.getters['account']);
const zones = computed(() => store.getters['home/getZones']);
const selectedZone = ref(undefined);
const registMode = ref(false);
let requestCount = 0;
let isSettingController = false;

onBeforeUnmount(() => {
  disConnectWifi();
});

const showPopAlert = (msg) => {
  emitter.emit('showPopAlert', {
    description: msg,
  });
};

const onSsidChange = (value) => {
  settingParams.value.ssid = value;
};

const onPasswordChange = (value) => {
  settingParams.value.pwd = value;
};

const onSelectZoneChange = (e) => {
  const zone = zones.value.find((zone) => zone._id == e.target.value);
  selectedZone.value = zone;
};

const onNextStepClick = async () => {
  try {
    if (wizardStep.value === 1) {
      // todo: 폰 wifi 켜져있는지 확인
      registMode.value = false;

      const currentWifi = await getWifiInfo();
      // settingParams.value.ssid = currentWifi.ssid;

      const controllerInfo = await connectWifi();
      connectedController.value.model = controllerInfo.model;
      connectedController.value._id = controllerInfo.serial_no;
      connectedController.value.version = controllerInfo.version;
    } else if (wizardStep.value === 2) {
      if (!isSettingController) {
        await settingController(
          settingParams.value,
          connectedController.value._id,
        );
        isSettingController = true;
      }

      requestCount = 0;
      emitter.emit('showLoading');
      const interval = setInterval(async () => {
        requestCount++;
        
        const result = await getControllerInfo(connectedController.value);
        if (result.resultCode) {
          if (result.resultData) {
            // 이미 등록된 컨트롤러일때 이관
            const transResult = await requestTransferController(
              connectedController.value,
              account.value._id,
            );

            if (transResult?.resultCode === "0000") {
              await store.dispatch('home/fetchAllZones');
            } else {
              showPopAlert(transResult.resultMessage);
              clearInterval(interval);
              emitter.emit('hideLoading');
              return;
            }
          } else {
            // 등록되지 않은 컨트롤러일때 등록모드
            registMode.value = true;
          }

          clearInterval(interval);
          wizardStep.value += 1;
          emitter.emit('hideLoading');
          return;
        }

        if (requestCount >= SettingConfig.requestCount) {
          clearInterval(interval);
          emitter.emit('hideLoading');
          showPopAlert('컨트롤러 이관에 실패했습니다.');
        }
      }, SettingConfig.requestInterval);

      return;
    } else {
      if (registMode.value) {
        // 등록모드일때
        if (wizardStep.value === 3) {
          if (!selectedZone.value) {
            showPopAlert('컨트롤러를 등록할 영역을 선택해주새요.');
            return;
          }

          const request = () => {
            return new Promise(async (resolve, reject) => {
              setTimeout(async () => {
                try {
                  const result = await requestRegistController(
                    connectedController.value,
                    selectedZone.value._id,
                  );
                  resolve(result);
                } catch (e) {
                  reject(e);
                }
              }, SettingConfig.requestInterval);
            });
          };

          emitter.emit('showLoading');
          let result = undefined;
          for (let i = 0; i < SettingConfig.requestCount; i++) {
            result = await request();
            if (result?.resultCode === "0000") {
              await store.dispatch('home/fetchAllZones');

              wizardStep.value += 1;
              emitter.emit('hideLoading');
              return;
            }
            if (result?.resultCode === "1001") {
              break;
            }
          }

          emitter.emit('hideLoading');
          showPopAlert(result?.resultMessage?.length > 0 ? result.resultMessage : "컨트롤러 등록에 실패했습니다.");
          return;
        } else if (wizardStep.value === 4) {
          emitter.emit('showPopAlert', {
            description: "이관받기가 완료되었습니다.",
            okCallback: router.go(-1),
            cancelCallback: router.go(-1)
          });          
          return;
        }
      } else {
        // 이관모드일때
        if (wizardStep.value === 3) {
          emitter.emit('showPopAlert', {
            description: "이관받기가 완료되었습니다.",
            okCallback: router.go(-1),
            cancelCallback: router.go(-1)
          });          
          return;
        }
      }
    }
    wizardStep.value += 1;
  } catch (err) {
    if (err?.length > 0) {
      showPopAlert(err);
    }
  }
};
</script>
