<template>
  <div id="listWidget" class="mb-4">
    <div>
      <h4 class="mb-3">전동커튼 등록</h4>
      <p class="mb-0">
        1. 전동커튼을 포함시킬 방을 선택해주세요.
      </p>
      <p class="mb-0">
        2. 다음 버튼을 클릭해주세요.
      </p>
    </div>

    <hr class="mb-3" />

    <div class="row" v-if="zone?.groups.length > 0">
      <div class="col-xl-6 mb-3">
        <div class="text-muted fs-12px mb-2"><b>방 선택</b></div>
        <div class="list-group" style="border-radius: 1.2rem">
          <ListGroupItem
            style="border-radius: 1.2rem; margin-bottom: 4px;"
            v-for="(group, index) in zone?.groups"
            :key="group._id"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`gridCheck${index}`"
                :value="group._id"
                @change="onSelectGroupChange"
                :checked="selectedGroup == group._id"
              />
              <label class="form-check-label" :for="`gridCheck${index}`">
                {{ group.name }}
              </label>
            </div>
          </ListGroupItem>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col" />
      <div class="col" />
      <div class="col">
        <button
          type="button"
          class="btn btn-primary btn-lg"
          style="width: 100%"
          @click="onRegistDeviceClick"
        >
          등록
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import Axios, { DeviceApi, GroupApi, ZoneApi } from '@/api';
import { useRouter, useRoute ,onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
import PopDialog from '@/common/components/popup/PopDialog.vue';
import useModal from '@/common/hooks/useModal';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import { Template, DeviceType } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const props = defineProps(['handleNextStep', 'serial']);
const router = useRouter();
const route = useRoute();
const store = useStore();
const zone = computed(() => store.getters['home/getCurrentZone']);
const selectedGroup = ref(undefined);

const onSelectGroupChange = (e) => {
  selectedGroup.value = e.target.value;
}

const onRegistDeviceClick = () => {
  if (!selectedGroup.value) {
    emitter.emit('showPopAlert', {
      description: '전동커튼을 포함할 방을 선택해주세요.',
    });
  }

  const controllerId = props.serial.slice(0, props.serial.indexOf("-"));
  requestAddDevice({
    groupId: selectedGroup.value,
    device: {
      controllerId: controllerId,
      name: controllerId,
      type: DeviceType.Curtain
    }
  });
}

const requestAddDevice = async (payload) => {
  try {
    const result = await Axios.post(
      GroupApi.REGIST_GROUP_DEVICES.replace(':id', payload.groupId),
      payload.device,
    );
    if (result.resultCode === '0000') {
      props.handleNextStep();
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (e) {
    console.log(e);
    emitter.emit('showPopAlert', {
      description: '장치 추가 중 오류가 발생하였습니다.',
    });
  }
};
</script>