<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">조명 컨트롤러 관리</h4>
    <p>
      영역 목록에서 컨트롤러 설정 또는 추가 버튼을 선택하면 조명 컨트롤러 등록 화면으로 이동할 수 있습니다.
    </p>

    <hr class="mb-4" v-if="zones?.length > 0" />

    <div class="row">
      <LightZoneItem
        v-for="(item, index) in zones"
        :key="item._id"
        :index="index"
        :data="item"
        @onDeleteClick="onDeleteZoneClick"
        @onZoneUpdated="onZoneUpdated"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Axios, { ZoneApi } from '@/api';
import PopDialog from '@/common/components/popup/PopDialog.vue';
import useModal from '@/common/hooks/useModal';
import LightZoneItem from './components/LightZoneItem.vue';
import { Template } from '@/common/constants';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';

const { emitter, mobile, logger } =
  getCurrentInstance().appContext.config.globalProperties;
let zones = ref([]);
let templateZoneNames = ref(Template.ZoneNames);
const store = useStore();
const router = useRouter();
const addZoneModal = reactive(useModal());
let addZoneName = ref('');

watch(zones, () => {
  console.log('HomeSettingPage.watch => zones : ', zones.value);
});

onBeforeMount(() => {
  console.log('HomeSettingPage.onMounted => onBeforeMount : ',{title:'네트워크 설정',isShowZone:false})
  emitter.emit('onChangeHeaderSetting',{title:'네트워크 설정',isShowZone:false});  
});
onBeforeUnmount(() =>{
  console.log('HomeSettingPage.onBeforeUnmount => onChangeHeaderSetting : ',{title:'네트워크 설정',isShowZone:false})
  emitter.emit('onChangeHeaderSetting',{title:'',isShowZone:true});
})
onMounted(async () => {
  requestZones();
  
});

const hasNotConfiguration = computed(() => {
  return zones.value.some((zone) => zone.controllers.length === 0);
});

const onAddZoneClick = async (name) => {
  if (zones.value && zones.value.length > 0 && hasNotConfiguration.value) {
    emitter.emit('showPopAlert', {
      description:
        '완료되지 않은 영역이 있습니다.\n미완료된 영역 설정을 완료 후 새 영역을 추가할 수 있습니다.',
    });
    return;
  }

  if (name === Template.ZoneNames[Template.ZoneNames.length - 1]) {
    addZoneModal.show();
    return;
  }

  let result = await requestAddZone(name);
  if (result && result.zone) {
    await requestZones();
    setTimeout(() => {
      emitter.emit('showPopAlert', {
        description: `[${result.zone.name}]영역이 추가되었습니다.\n컨트롤러를 설정 화면으로 이동합니다.`,
        okCallback: () => {
          router.push(`/settings/zones/${result.zone._id}/regist-controllers`);
        },
        cancelCallback: () => {},
      });
    }, 300);
  }
};

const onAddDeviceClick = () => {
  console.log('add device click');
};

const onDeleteZoneClick = (payload) => {
  console.log('HomeSettingPage.onDeleteZoneClick => payload :', payload);

  let groups = zones.value.find((z) => z._id == payload.zoneId).groups;
  if (groups.length > 0) {
    emitter.emit('showPopAlert', {
      description: '방정보가 포함된 영역은 삭제할 수 없습니다.'
    });
    return;
  }
  requestDeleteZone(payload.zoneId);
};

const onZoneUpdated = () => {
  requestZones();
};

const onAddZoneConfirmClick = async () => {
  console.log(addZoneName.value);
  if (addZoneName.value.length === 0) {
    emitter.emit('showPopAlert', {
      description: "영역 이름을 입력해주세요.",
    });
    return;
  }

  addZoneModal.hide();
  let result = await requestAddZone(addZoneName.value);
  if (result && result.zone) {
    await requestZones();
    setTimeout(() => {
      emitter.emit('showPopAlert', {
        description: `[${result.zone.name}]영역이 추가되었습니다.\n컨트롤러를 설정 화면으로 이동합니다.`,
        okCallback: () => {
          router.push(`/settings/zones/${result.zone._id}/regist-controllers`);
        },
        cancelCallback: () => {},
      });
    }, 300);
  }
};

const requestZones = async () => {
  try {
    const result = await Axios.get(ZoneApi.ZONES, { isOwner: true });
    console.log('HomeSettingPage.requestZones => result : ', result);
    if (result.resultCode === '0000') {
      zones.value = result.resultData;
      return;
    }
    if (result.resultMessage) {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
    return false;
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '기존 영역 조회에 실패했습니다.',
    });
    return false;
  }
};
const requestAddZone = async (name) => {
  try {
    const result = await Axios.post(ZoneApi.REGIST_ZONE, { name: name });
    if (result.resultCode === '0000') {
      store.commit('home/addZone', result.resultData);
      return { zone: result.resultData, result: true };
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
    return { result: false, zone: undefined };
  } catch (e) {
    console.log(e);
    emitter.emit('showPopAlert', {
      description: '영역 추가중 오류가 발생하였습니다.',
    });
  }
};
const requestDeleteZone = async (zoneId) => {
  try {
    const result = await Axios.delete(
      ZoneApi.DELETE_ZONE.replace(':id', zoneId),
    );
    if (result.resultCode === '0000') {
      requestZones();
      store.commit('home/deleteZone', zoneId);
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '기존 영역 조회에 실패했습니다.',
    });
  }
};
</script>
