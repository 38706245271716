<template>
  <div class="col" :style="'padding: 0px; ' + props.itemStyle || ''">
    <div class="list-group" ref="boilerItem" style="border-radius: 1.2rem;" @click="onBoilerPowerClicked" v-touch:press="onTouchDown" v-touch:drag="onTouchDrag" v-touch:release="onTouchRelease" v-touch:hold="onTouchHold">
      <div class="list-group-item d-flex align-items-center" :style="`height: 4rem; padding: 2px 0px; background: ${getPower ? '#ffe082' : 'inherits'};`">
        <i class="fa-solid fa-temperature-three-quarters ms-3 me-2" />
        <div class="flex-fill" style="font-size: 0.8rem">
          보일러
          <br>
          {{ deviceState(2) ? currentTempText : "외출 중" }}
        </div>
        <!-- <div class="mx-1" @click.stop="onBoilerInfoClicked">
          <i class="fa fa-ellipsis" />
        </div> -->
        <div class="mx-1 h-100" @click.stop="onSettingClicked">
          <i :class="`fa-solid fa-gear me-3 mt-4`" style="font-size: 0.8rem" />
        </div>
        <!-- <div class="mx-1 h-100" @click.stop="onExpandClicked">
          <i :class="`fa ${isExpand ? 'fa-chevron-up' : 'fa-chevron-down'} me-3 mt-4`" style="font-size: 0.8rem" />
        </div> -->
      </div>
    </div>
    <!-- <transition name="fade">
      <div v-if="isExpand">
        <RoomBoilerDeviceItem v-for="device in boilerDevices" :key="device._id" :roomName="props.roomName" :roomId="props.roomId" :deviceId="device._id" />
      </div>
    </transition> -->
    <!-- <div class="list-group" ref="boilerItem" style="border-radius: 1.2rem;" v-else @click="onBoilerPowerClicked" @mousedown="onMouseDown">
      <div class="list-group-item d-flex align-items-center" :style="`height: 4rem; background: ${deviceState(2) ? `linear-gradient(to right, #ffe082 ${percent}%, #fff8e1 ${percent}%)` : 'inherits'};`">
        <div class="flex-fill" style="font-size: 0.8rem">
          <i class="fa-solid fa-temperature-three-quarters me-2" />
          {{ props.roomName }} 보일러 {{ deviceState(2) ? currentTempText : "" }}
        </div>
        <div class="ms-3" @click.stop="onBoilerInfoClicked">
          <i class="fa fa-chevron-right" style="font-size: 0.8rem" />
        </div>
      </div>
    </div> -->
  </div>
        
  <BoilerItemModal
    v-if="boilerItemModal.visible"
    :visible="boilerItemModal.visible"
    :cancelCallback="boilerItemModal.hide"
    :roomName="props.roomName"
    :roomId="props.roomId"
    :handlePowerClick="onBoilerPowerClicked"
  />
</template>

<script setup>
import { reactive, defineProps, getCurrentInstance, computed, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import { DeviceType, PowerState } from '@/common/constants';
import BoilerItemModal from './BoilerItemModal.vue';
import useModal from '@/common/hooks/useModal';
import useMqttControl from '@/common/hooks/useMqttControl';
import useDeviceTouch from '@/common/hooks/useDeviceTouch';
import RoomBoilerDeviceItem from './RoomBoilerDeviceItem.vue';

const mqttControl = reactive(useMqttControl());

const { emit } = getCurrentInstance();
const props = defineProps(['roomId', 'roomName', 'itemStyle']);
const store = useStore();
const boilerItemModal = reactive(useModal());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const boilerItem = ref(null);
const { 
  handlePower, 
  handleTouchDown, 
  handleTouchDrag, 
  handleTouchRelease,
  handleTouchHold
} = useDeviceTouch(props.roomId);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});

const boilerDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 2,
  );
});
const boilerCurrentTemp = computed(() => {
  return boilerDevices.value.length > 0 && boilerDevices.value[0].item.currentTemp ? boilerDevices.value[0].item.currentTemp : 20;
});
const boilerTargetTemp = computed(() => {
  return boilerDevices.value.length > 0 && boilerDevices.value[0].item.targetTemp ? boilerDevices.value[0].item.targetTemp : 18;
});

const currentTempText = computed(() => {
  return `${parseFloat(boilerCurrentTemp.value)}°C (${parseFloat(boilerTargetTemp.value)}°C)`;
})

const getPower = computed(() => {
  return boilerDevices.value.some((d) => d.item?.onoff === PowerState.On);
});

onMounted(() => {
});

const deviceState = (type) => {
  return devices.value
    .filter((d) => d.type === type)
    .some((d) => d.item.onoff === PowerState.On);
};

const onBoilerPowerClicked = (e) => {
  handlePower(() => {
    // if (zone.value.isEditMode) {
    //   emitter.emit('showPopAlert', {
    //     description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    //   });
    //   return;
    // }
    emit('onPowerClicked', {
      id: props.roomId,
      type: 2,
      onoff: getPower.value ? PowerState.Off : PowerState.On,
    });
  });
};

const onBoilerInfoClicked = (e) => {
  // if (zone.value.isEditMode) {
  //   emitter.emit('showPopAlert', {
  //     description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
  //   });
  //   return;
  // }
  boilerItemModal.show();
}

const onSettingClicked = (e) => {
  onBoilerInfoClicked();
}

const tempChanged = (e) => {
  // boilerDevices.value.forEach((device) => {
  //   mqttControl.onPublish(device.controllerId, device._id, {
  //     serial: device.controllerId,
  //     cmd: 'c',
  //     type: device.type,
  //     data: {
  //       serial: device.item.serial,
  //       onoff: device.item.onoff,
  //       temperature: parseInt(targetTemp.value),
  //     },
  //   });
  // })
};

const onTouchDown = (e) => {
  if (deviceState(2)) {
    handleTouchDown(e);
  }
}

const onTouchDrag = (e) => {
  handleTouchDrag(e, (screenX, startX) => {
    // percent.value = percent.value + (screenX - startX) / boilerItem.value.clientWidth * 100.0;
    // percent.value = percent.value <= 0 ? 0 : percent.value;
    // percent.value = percent.value >= 100 ? 100 : percent.value;
    // targetTemp.value = parseInt(percent.value  * 0.27) + 9;
  });
}

const onTouchRelease = (e) => {
  handleTouchRelease(e, tempChanged);
}

const onTouchHold = (e) => {
  handleTouchHold(e, onBoilerInfoClicked);
}
</script>