<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">조명장치 관리</h4>
    <p>
      방 목록을 선택하면 조명장치 추가 화면으로 이동할 수 있습니다.
    </p>

    <!-- <CardBody>
      <button
        type="button"
        class="btn btn-outline-primary mb-1 me-1"
        style="min-width: 50px"
        v-for="(item, index) in templateGroupNames"
        :key="index"
        @click="onAddGroupClick(item)"
      >
        {{ item }}
      </button>
    </CardBody> -->

    <hr class="mb-4" v-if="zone?.groups?.length > 0" />

    <div class="row">
      <LightGroupItem
        v-for="(item, index) in zone?.groups"
        :key="item._id"
        :index="index"
        :data="item"
        @onDeleteClick="onDeleteGroupClick"
        @onGroupUpdated="onGroupUpdated"
      />
    </div>

    <PopDialog
      title="방추가"
      :visible="addGroupModal.visible"
      :okCallback="onAddGroupConfirmClick"
      :cancelCallback="addGroupModal.hide"
    >
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        placeholder="방 이름을 입력해주세요."
        v-model="addGroupName"
      />
    </PopDialog>

    <PopDialog
      title="컨트롤러추가"
      :visible="addControllerModal.visible"
      :okCallback="onAddControllerConfirmClick"
      :cancelCallback="addControllerModal.hide"
    >
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        placeholder="컨트롤러 시리얼넘버를 입력해주세요."
        v-model="addController._id"
      />
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        placeholder="컨트롤러 모델명을 입력해주세요."
        v-model="addController.model"
      />
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        placeholder="컨트롤러 macAddress를 입력해주세요."
        v-model="addController.macAddress"
      />
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        placeholder="컨트롤러 firmware 입력해주세요."
        v-model="addController.firmwareVersion"
      />
    </PopDialog>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import Axios, { ZoneApi, GroupApi, CtrlApi } from '@/api';
import { useRouter, useRoute ,onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
import PopDialog from '@/common/components/popup/PopDialog.vue';
import useModal from '@/common/hooks/useModal';
import LightGroupItem from './components/LightGroupItem.vue';
import { Template } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const router = useRouter();
const route = useRoute();
const props = defineProps(['data']);
let addGroupName = ref('');
let addController = ref({
  _id: '',
  model: '',
  macAddress: '',
  firmwareVersion: '',
});
const addGroupModal = reactive(useModal());
const addControllerModal = reactive(useModal());
const store = useStore();
const zone = computed(() => store.getters['home/getCurrentZone']);
const forceRoute = ref(false);
let templateGroupNames = ref(Template.GroupNames);

onBeforeMount(() => {
  emitter.emit('onChangeHeaderSetting',{title:'네트워크 설정',isShowMenu:false,isShowZone:false,isShowProfile:false});  
});
onBeforeUnmount(() =>{
  emitter.emit('onChangeHeaderSetting',{title:'',isShowMenu:true,isShowZone:true,isShowProfile:true});
})
onMounted(() => {
  requestZone();
  console.log('GroupSettingPage.onMounted => zone : ', zone.value);
});

watch(zone, () => {
  console.log('GroupSettingPage.watch => zone : ', zone.value);
});

const onAddGroupClick = (name) => {  
  if (name === Template.GroupNames[Template.GroupNames.length - 1]) {
    addGroupModal.show();
    return;
  }
  requestAddGroup({ zoneId: zone.value._id, groupName: name });
};
const onAddGroupConfirmClick = () => {
  console.log(addGroupName.value);
  if (addGroupName.value.length === 0) {
    emitter.emit('showPopAlert', {
      description: "방 이름을 입력해주세요.",
    });
    return;
  }

  addGroupModal.hide();
  requestAddGroup({ zoneId: zone.value._id, groupName: addGroupName.value });
};
const onAddControllerConfirmClick = () => {
  console.log(addController.value);
  requestAddController({
    zoneId: zone.value._id,
    controller: addController.value,
  });
};

const onDeleteGroupClick = (payload) => {
  console.log('GroupSettingPage.onDeleteZoneClick => payload :', payload);

  let devices = zone.value.groups.find((g) => g._id == payload.groupId).devices;
  if (devices.length > 0) {
    emitter.emit('showPopAlert', {
      description: '단말정보가 포함된 방은 삭제할 수 없습니다.',
    });
    return;
  }
  requestDeleteGroup(payload.groupId);
};

const onGroupUpdated = () => {
  requestZone();
};

const requestZone = async () => {
  try {
    const result = await Axios.get(
      ZoneApi.ZONE_INFO.replace(':id', zone.value._id),
    );
    if (result.resultCode === '0000') {
      zone.value = result.resultData;
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '영역 조회에 실패했습니다.',
    });
  }
};

const requestAddController = async (payload) => {
  try {
    const result = await Axios.post(
      ZoneApi.REGIST_ZONE_CTRLS.replace(':id', payload.zoneId),
      payload.controller,
    );
    if (result.resultCode === '0000') {
      requestZone();
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (e) {
    console.log(e);
    emitter.emit('showPopAlert', {
      description: '방 추가중 오류가 발생하였습니다.',
    });
  }
};

const requestAddGroup = async (payload) => {
  try {
    const result = await Axios.post(
      ZoneApi.REGIST_ZONE_GROUPS.replace(':id', payload.zoneId),
      { name: payload.groupName },
    );
    if (result.resultCode === '0000') {
      requestZone();
      store.commit('home/addGroup', {
        zoneId: zone.value._id,
        data: result.resultData.group,
      });
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (e) {
    console.log(e);
    emitter.emit('showPopAlert', {
      description: '방 추가중 오류가 발생하였습니다.',
    });
  }
};
const requestDeleteGroup = async (groupId) => {
  let url = GroupApi.DELETE_GROUP.replace(':id', groupId);
  console.log('requestDeleteGroup => url : ', url);
  try {
    const result = await Axios.delete(
      GroupApi.DELETE_GROUP.replace(':id', groupId),
    );
    if (result.resultCode === '0000') {
      requestZone();
      store.commit('home/deleteGroup', {
        zoneId: zone.value._id,
        groupId: groupId,
      });
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '방 삭제중 오류가 발생하였습니다.',
    });
  }
};
</script>
