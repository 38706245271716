<template>
  <PopDialog2
    title="방 정보 수정"
    :visible="props.visible"
    :okCallback="onOkClick"
    :cancelCallback="onCancelClick"
  >
    <div class="md-layout">
      <div class="md-layout-item md-size-50 mx-auto text-center mb-4">
        <!-- <h2 class="title mt-4">{{props.roomName}} 보일러</h2> -->
        <h2 class="title mt-4">커튼</h2>
        <button
          type="button"
          class="btn btn-light mb-4"
          :style="`width: 6rem; height: 6rem; border-radius: 3rem; background-color: ${getPower ? '#ffe082' : 'inherits'};`"
          @click="props.handlePowerClick"
        >
        <!-- <button
          type="button"
          class="btn btn-light mb-4"
          :style="`width: 6rem; height: 6rem; border-radius: 3rem; background-color: ${props.curtainStatus == 1 ? '#ffe082' : (props.curtainStatus == 0 ? 'inherits' : '#e4d5a8')}`"
          @click="props.handlePowerClick"
        > -->
          <span style="font-size: 1.0rem">
            {{ getPower ? "열기" : "닫기" }}
            <!-- {{ props.curtainStatus == 0 ? "닫기" : (props.curtainStatus == 1 ? "열기" : "중지")}} -->
          </span>
        </button>

        <div style="margin-right: 20px;">
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <Slider
              name="닫힘 정도"
              id="percent"
              :default="currentPercent"
              :nameWidth="3"
              :min="0"
              :max="100"
              v-on:onSliderChanged="onPercentChanged"
            />
          </ListGroupItem>
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <div class="container" style="padding-left: 0px; padding-right: 0px; height: 34.5px;">
              <div class="row" style="text-align: center;">
                <div
                  :class="`col-3`"
                  :style="`margin-top: 8px`"
                >
                  <label :for="props.id" class="form-label fw-normal">
                    커튼 방향
                  </label>
                </div>

                <div class="col" style="width: 100%; margin-top: 8px; margin-left: 20px; text-align: left;">
                  <input
                    class="form-check-input"
                    id="reverseCheck"
                    type="checkbox"
                    :checked="currentReverse"
                    @change="onReverseChange"
                  />
                  <label class="form-check-label ms-2" for="reverseCheck">
                    {{ currentReverse ? "현재 커튼이 역방향으로 움직입니다." : "현재 커튼이 정방향으로 움직입니다." }}
                  </label>
                </div>
              </div>
            </div>
          </ListGroupItem>

          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <div class="container" style="padding-left: 0px; padding-right: 0px;">
              <div class="row">
                <div
                  :class="`col-3`"
                  :style="`margin-top: 8px`"
                >
                </div>
                <div class="col">
                  <div class="flex-fill">
                    <div
                      class="fw-400 text-muted text-end me-1"
                      style="font-size: 0.8rem"
                      @click="onSettingMortorAddressClick"
                    >
                      모터 주소 설정&nbsp;<i class="fa fa-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ListGroupItem>
        </div>
      </div>
    </div>
  </PopDialog2>
        
  <CurtainMortorAddressModal
    v-if="curtainMortorAddressModal.visible"
    :visible="curtainMortorAddressModal.visible"
    :cancelCallback="curtainMortorAddressModal.hide"
    :roomName="props.roomName"
    :roomId="props.roomId"
  />
</template>

<script setup>
import { ref, defineProps, computed, getCurrentInstance, reactive } from 'vue';
import { useStore } from 'vuex';
import { DeviceType, PowerState, MQTTTempCommand, MQTTCurtainCommand } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';
import useModal from '@/common/hooks/useModal';
import PopDialog2 from '@/common/components/popup/PopDialog2.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import CurtainMortorAddressModal from './CurtainMortorAddressModal.vue';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const curtainMortorAddressModal = reactive(useModal());
const store = useStore();
const props = defineProps([
  'visible',
  'okCallback',
  'cancelCallback',
  'roomName',
  'roomId',
  'handlePowerClick',
  'curtainStatus',
  'afterStatus'
]);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});
const curtainDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 3,
  );
});

const currentPercent = computed(() => curtainDevices.value.length > 0 ? curtainDevices.value[0].item.percent : 50);
const currentReverse = computed(() => curtainDevices.value.length > 0 ? (curtainDevices.value[0].item.reverse == 1 ? true : false) : false);
const currentAddress = computed(() => curtainDevices.value.length > 0 ? curtainDevices.value[0].item.address : 1);

const getPower = computed(() => {
  return curtainDevices.value.some((d) => d.item?.close === PowerState.On);
  // return curtainStatus.value === 1 || afterStatus.value === 1 ? true : false;
});

const deviceState = (type) => {
  return devices.value
    .filter((d) => d.type === type)
    .some((d) => d.item.close === PowerState.On);
  // return props.curtainStatus === 1 || props.afterStatus === 1 ? true : false;
};

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};

const onPercentChanged = (e) => {
  curtainDevices.value.forEach((device) => {
    mqttControl.onPublishTemp(device.controllerId, MQTTCurtainCommand.IsSetClosePercent, e);
  });
};

const onReverseChange = (e) => {
  curtainDevices.value.forEach((device) => {
    mqttControl.onPublishTemp(device.controllerId, MQTTCurtainCommand.IsWriteDirection, e.target.checked ? 1 : 0);
  });
}

const onSettingMortorAddressClick = () => {
  curtainMortorAddressModal.show();
}
</script>