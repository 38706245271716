<template>
  <WizardTempWifiConnectStep
    v-if="wizardStep === 1"
    :handleNextStep="onNextStepClick"
  />
  <WizardTempDeviceRegistStep
    v-if="wizardStep === 2"
    :handleNextStep="onNextStepClick"
    :serial="serialCode"
  />
  <WizardTempRegistCompleteStep
    v-if="wizardStep === 3"
    :handleNextStep="onNextStepClick"
  />
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  reactive,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useMobile from '@/common/hooks/useMobile';
import useMqttControl from '@/common/hooks/useMqttControl';
import WizardTempWifiConnectStep from './components/wizard/WizardTempWifiConnectStep';
import WizardTempDeviceRegistStep from './components/wizard/WizardTempDeviceRegistStep';
import WizardTempRegistCompleteStep from './components/wizard/WizardTempRegistCompleteStep';

const wizardStep = ref(1);
const serialCode = ref('');

const onNextStepClick = async (params) => {
  try {
    if (wizardStep.value === 1) {
      serialCode.value = params.serialCode;
    } else if (wizardStep.value === 2) {
    } else if (wizardStep.value === 3) {
    } else {
      return;
    }

    wizardStep.value += 1;
  } catch (err) {
    console.log(err);
    if (err?.length > 0) {
      showPopAlert(err);
    }
  }
};
</script>