<template>
  <div id="listWidget" class="mb-4">
    <div>
      <h4 class="mb-3">전동커튼 등록 완료</h4>
      <p class="mb-0">
        전동커튼 등록이 완료되었습니다.
      </p>
    </div>

    <hr class="mb-3" />

    <div class="row">
      <div class="col" />
      <div class="col" />
      <div class="col">
        <button
          type="button"
          class="btn btn-primary btn-lg"
          style="width: 100%"
          @click="onRegistCompleteClick"
        >
          완료
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const router = useRouter();

onMounted(async () => {
  await store.dispatch('home/fetchAllZones');
});

const onRegistCompleteClick = () => {
  router.go(-1);
}
</script>
