<template>
  <ListGroupItem>
    <div class="flex-1 text-break">
      <div>{{ props.item.nickName }}</div>
      <div class="text-gray-600">공유된 영역: {{ zonesText }}</div>
    </div>
    <div>
      <a class="btn btn-default w-80px" @click="onCancelClick">공유취소</a>
    </div>
  </ListGroupItem>
</template>

<script setup>
import { useStore } from 'vuex';
import { reactive, getCurrentInstance, defineProps, computed } from 'vue';
import Axios, { AuthApi } from '@/api';
import useModal from '@/common/hooks/useModal';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const store = useStore();
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const zones = computed(() => store.getters['home/getZones']);
const sharedZones = computed(() => zones.value.filter(zone => zone.ownerId == props.item._id));
const zonesText = computed(() => sharedZones.value.map(zone => zone.name).join(", "));

const props = defineProps(['item', 'handleShareOwnerUpdated']);
const updateModal = reactive(useModal());

const onCancelClick = () => {
  emitter.emit('showPopConfirm', {
    description: '해당 사용자로부터 받은 공유 영역이 제거됩니다.\n계속하시겠습니까?',
    okCallback: () => {
      requestCancelShare();
    }
  });
};

const requestCancelShare = async () => {
  try {
    const result = await Axios.post(AuthApi.CANCEL_SHARE, { ownerId: props.item._id }, 'auth');
    if (result.resultCode === '0000') {
      props.handleShareOwnerUpdated();
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '공유 취소에 실패했습니다.',
    });
  }
};
</script>
