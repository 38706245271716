<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">설정</h4>
    <!-- <p>
      영역을 추가해주세요. 아래 <code style="font-size: 100%">영역 버튼</code>을
      누르시면 해당 영역이 추가 됩니다. 추가된 영역의
      <code style="font-size: 100%">상세보기 버튼</code>을 누르시면 방 추가
      화면으로 이동할 수 있습니다.
    </p> -->
    <div class="row">
      <div class="col-xl-6">
        <div class="text-muted fs-12px mb-2"><b>설정</b></div>
        <div class="list-group mb-3">
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onSettingZoneClick">
            <div class="flex-fill">
              <div class="fw-600">영역 설정</div>
              <div class="fs-12px text-muted">
                집이나 사무실 등 영역을 설정 할 수 있습니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onSettingLightClick">
            <div class="flex-fill">
              <div class="fw-600">조명 설정</div>
              <div class="fs-12px text-muted">
                조명 장치를 추가/삭제 할 수 있습니다.<br>기타 설정을 할 수 있습니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          
          <ListGroupItem v-if="isEnabledVoiler" style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onSettingTempClick">
            <div class="flex-fill">
              <div class="fw-600">온도 조절기 설정</div>
              <div class="fs-12px text-muted">
                온도 조절기를 추가/삭제 할 수 있습니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem v-if="isEnabledCurtain" style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onSettingCurtainClick">
            <div class="flex-fill">
              <div class="fw-600">전동커튼 설정</div>
              <div class="fs-12px text-muted">
                전동커튼 장치를 추가/삭제 할 수 있습니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onTransferSendClick">
            <div class="flex-fill">
              <div class="fw-600">이관 하기</div>
              <div class="fs-12px text-muted">
                다른 사용자에게 설정 데이터를 이관합니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onTransferReceiveClick">
            <div class="flex-fill">
              <div class="fw-600">이관 받기</div>
              <div class="fs-12px text-muted">
                이전 사용자의 설정 데이터를 나에게 이관합니다.
              </div>
            </div>
            <div>
              <div class="form-switch h-100">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <!-- <ListGroupItem style="border-radius: 1.2rem;" @click="onInitZoneClick">
            <div class="flex-fill">
              <div class="fw-600">초기화</div>
              <div class="fs-12px text-muted">
                영역에 포함된 모든 정보를 초기화합니다.
              </div>
            </div>
            <div>
              <div class="form-switch h-100">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem> -->
        </div>

        <!-- <div class="text-muted fs-12px mb-2"><b>컨트롤러</b></div>
        <div class="list-group" style="border-radius: 1.2rem">
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onTransferRegistClick">
            <div class="flex-fill">
              <div class="fw-600">최초 등록</div>
              <div class="fs-12px text-muted">
                사업자 또는 시공자가 시스템을 설치할 때 최초로 장치등을
                등록합니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onTransferChangeClick">
            <div class="flex-fill">
              <div class="fw-600">와이파이 변경</div>
              <div class="fs-12px text-muted">
                설치된 지역의 Wifi가 변경되었을 때 변경된 wifi 정보를
                컨트롤러에 재설정 합니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const router = useRouter();

const onSettingZoneClick = () => router.push('/settings/zones');
const onSettingLightClick = () => router.push('/settings/light');
const onSettingTempClick = () => router.push('/settings/temp');
const onSettingCurtainClick = () => router.push('/settings/curtain');
const onTransferSendClick = () => router.push('/settings/send');
const onTransferReceiveClick = () => router.push('/settings/receive');
const onInitZoneClick = () => router.push('/settings/init');

const isEnabledVoiler = process.env.VUE_APP_ENABLE_TEMP_CONTROLLER === 'true' || false;
const isEnabledCurtain = process.env.VUE_APP_ENABLE_TEMP_CONTROLLER === 'true' || false;

console.log(`process.env.VUE_APP_ENABLE_TEMP_CONTROLLER  : `,process.env.VUE_APP_ENABLE_TEMP_CONTROLLER )

</script>

<!-- <script>
export default {
	data() {
		return {
			isEnabledVoiler: process.env.VUE_APP_ENABLE_TEMP_CONTROLLER === 'true', // Ensure it's a boolean
      isEnabledCurtain: process.env.VUE_APP_ENABLE_TEMP_CONTROLLER === 'true', // Ensure it's a boolean
		}
	}
}
</script> -->
