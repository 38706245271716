import IntroPage from '@/views/main/IntroPage';
import NotFoundPage from '@/views/main/NotFoundPage';
import RoomsPage from '@/views/rooms/RoomsPage';
import RoomsInfoPage from '@/views/rooms/RoomsInfoPage';
import LightInfoPage from '@/views/rooms/LightInfoPage';
import LoginPage from '@/views/auth/LoginPage';
import JoinPage from '@/views/auth/JoinPage';
import CertPage from '@/views/auth/CertPage';
import CertCompletePage from '@/views/auth/CertCompletePage';
// import FindEmailPage from '@/views/auth/FindEmailPage';
import FindPasswordPage from '@/views/auth/FindPasswordPage';
import ChangePasswordPage from '@/views/auth/ChangePasswordPage';
import ChangePasswordCompletePage from '@/views/auth/ChangePasswordCompletePage';
import KakaoPage from '@/views/auth/callback/KakaoPage';
import GooglePage from '@/views/auth/callback/GooglePage';
import ApplePage from '@/views/auth/callback/ApplePage';
import DevicesPage from '@/views/devices/DevicesPage';
import AddDevicePage from '@/views/devices/AddDevicePage';
import AddZonePage from '@/views/zones/AddZonePage';
import AddRoomPage from '@/views/rooms/AddRoomPage';
import SchedulesPage from '@/views/schedules/SchedulesPage';
import ProfilePage from '@/views/users/ProfilePage';
import SharePage from '@/views/users/SharePage';
import DeleteUserPage from '@/views/users/DeleteUserPage';
import AppInfoPage from '@/views/application/AppInfoPage';
import AppUpdatePage from '@/views/application/AppUpdatePage';
import HomeSettingPage from '@/views/settings/HomeSettingPage';
import ControllerSettingPage from '@/views/settings/ControllerSettingPage';
import ControllerRegistPage from '@/views/settings/ControllerRegistPage';
import GroupSettingPage from '@/views/settings/GroupSettingPage';
import DeviceSettingPage from '@/views/settings/DeviceSettingPage';
import TransferPage from '@/views/settings/TransferPage';
import BatchSettingPage from '@/views/settings/BatchSettingPage';
import LightSettingPage from '@/views/settings/LightSettingPage';
import LightControllersSettingPage from '@/views/settings/LightControllersSettingPage';
import LightGroupsSettingPage from '@/views/settings/LightGroupsSettingPage';
import LightDevicesSettingPage from '@/views/settings/LightDevicesSettingPage';
import FadeSettingPage from '@/views/settings/FadeSettingPage';
import TempSettingPage from '@/views/settings/TempSettingPage';
import TempRegistPage from '@/views/settings/TempRegistPage';
import TempSettingListPage from '@/views/settings/TempSettingListPage';
import CurtainSettingPage from '@/views/settings/CurtainSettingPage';
import VirtualButtonSettingPage from '@/views/settings/VirtualButtonSettingPage';
import VirtualButtonUpdatePage from '@/views/settings/VirtualButtonUpdatePage';
import TransferRegistPage from '@/views/settings/TransferRegistPage';
import TransferChangePage from '@/views/settings/TransferChangePage';
import TransferSendPage from '@/views/settings/TransferSendPage';
import TransferReceivePage from '@/views/settings/TransferReceivePage';
import TransferInitZonePage from '@/views/settings/TransferInitZonePage';
import NoticePage from '@/views/notices/NoticePage';
const routes = [
  {
    path: '/',
    component: IntroPage,
  },
  {
    path: '/rooms',
    component: RoomsPage,
  },
  {
    path: '/rooms/:roomId',
    component: RoomsInfoPage,
  },
  // {
  //   path: '/rooms/:roomId/device/:id',
  //   component: LightInfoPage,
  // },
  {
    name: "login",
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/join',
    component: JoinPage,
  },
  // {
  //   path: '/find/email',
  //   component: FindEmailPage,
  // },
  {
    path: '/find/password',
    component: FindPasswordPage,
  },
  {
    path: '/change/password',
    component: ChangePasswordPage,
  },
  {
    path: '/change/password/complete',
    component: ChangePasswordCompletePage,
  },
  {
    path: '/auth/cert',
    component: CertPage,
  },
  {
    path: '/auth/cert/complete',
    component: CertCompletePage,
  },
  {
    path: '/oauth/kakao',
    component: KakaoPage,
  },
  {
    path: '/oauth/google',
    component: GooglePage,
  },
  {
    path: '/callback/apple',
    component: ApplePage,
  },
  {
    path: '/devices',
    component: DevicesPage,
  },
  {
    path: '/add/zone',
    component: AddZonePage,
  },
  {
    path: '/add/room',
    component: AddRoomPage,
  },
  {
    path: '/add/device',
    component: AddDevicePage,
  },
  {
    path: '/schedules',
    component: SchedulesPage,
  },
  {
    path: '/settings/zones',
    component: HomeSettingPage,
  },
  {
    path: '/settings/zones/:zoneId/groups',
    component: GroupSettingPage,
  },
  {
    path: '/settings/zones/:zoneId/regist-controllers',
    component: ControllerRegistPage,
  },
  {
    path: '/settings/zones/:zoneId/setting-controllers',
    component: ControllerSettingPage,
  },
  {
    path: '/settings/zones/:zoneId/groups/:groupId/devices',
    component: DeviceSettingPage,
  },
  {
    path: '/settings',
    component: TransferPage,
  },
  {
    path: '/settings/light',
    component: LightSettingPage,
  },
  {
    path: '/settings/light/controllers',
    component: LightControllersSettingPage,
  },
  {
    path: '/settings/light/groups',
    component: LightGroupsSettingPage,
  },
  {
    path: '/settings/light/groups/:groupId/devices',
    component: LightDevicesSettingPage,
  },
  {
    path: '/settings/light/batch',
    component: BatchSettingPage,
  },
  {
    path: '/settings/light/fade',
    component: FadeSettingPage,
  },
  {
    path: '/settings/light/change',
    component: TransferChangePage,
  },
  {
    path: '/settings/regist',
    component: TransferRegistPage,
  },
  {
    path: '/settings/temp',
    component: TempSettingPage,
  },
  {
    path: '/settings/temp/regist',
    component: TempRegistPage,
  },
  {
    path: '/settings/temp/list',
    component: TempSettingListPage,
  },
  {
    path: '/settings/curtain',
    component: CurtainSettingPage,
  },
  {
    path: '/settings/send',
    component: TransferSendPage,
  },
  {
    path: '/settings/receive',
    component: TransferReceivePage,
  },
  {
    path: '/settings/init',
    component: TransferInitZonePage,
  },
  {
    path: '/virtual/:groupId',
    component: VirtualButtonSettingPage,
  },
  {
    path: '/virtual/:groupId/:virtualId',
    component: VirtualButtonUpdatePage,
  },
  {
    path: '/profile',
    component: ProfilePage,
  },
  {
    path: '/share',
    component: SharePage,
  },
  {
    path: '/delete/user',
    component: DeleteUserPage,
  },
  {
    path: '/info',
    component: AppInfoPage,
  },
  {
    path: '/update',
    component: AppUpdatePage,
  },
  {
    path: '/notices',
    component: NoticePage,
  },
  {
    path: '/404',
    component: NotFoundPage,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

export default routes;
