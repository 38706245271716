<template>
  <PopDialog2
    title="커튼 모터 주소 설정"
    :visible="props.visible"
    :okCallback="onOkClick"
    :cancelCallback="onCancelClick"
  >
    <div class="md-layout">
      <div class="md-layout-item md-size-50 mx-auto text-center mb-4">
        <!-- <h2 class="title mt-4">{{props.roomName}} 보일러</h2> -->
        <h2 class="title mt-4 mb-4">커튼 모터 주소</h2>
        <div class="row" role="group" style="margin-left: 50px; margin-right: 50px;">
          <button type="button" class="btn btn-light col" 
            style="padding:0px; margin: 0px; max-width: 40px; border-radius: 5px 0px 0px 5px;"
            @click="onAddressDownClick"
          >
            -
          </button>
          <div class="col" role="group" style="padding:0px; margin: 0px; width: 40%;">
            <label class="form-label fw-normal" style="margin: 0px; padding: 0px; border: 1px solid #ebeef4; align-content: center; width: 100%; height: 100%;">
              {{ currentAddress }}
            </label>
          </div>
          <button type="button" class="btn btn-light col" 
            style="max-width: 40px; border-radius: 0px 5px 5px 0px;"
            @click="onAddressUpClick"
          >
            +
          </button>
        </div>
      </div>
    </div>
  </PopDialog2>
</template>

<script setup>
import { ref, defineProps, computed, getCurrentInstance, reactive } from 'vue';
import { useStore } from 'vuex';
import { MQTTCurtainCommand } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';
import PopDialog2 from '@/common/components/popup/PopDialog2.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const store = useStore();
const props = defineProps([
  'visible',
  'okCallback',
  'cancelCallback',
  'roomName',
  'roomId',
  'handlePowerClick'
]);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});
const curtainDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 3,
  );
});
const currentAddress = computed(() => curtainDevices.value.length > 0 ? curtainDevices.value[0].item.address : 1);

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};

const onAddressDownClick = (e) => {
  let address = parseInt(currentAddress.value) - 1;
  address = address <= 1 ? 1 : address;
  curtainDevices.value.forEach((device) => {
    mqttControl.onPublishTemp(device.controllerId, MQTTCurtainCommand.RS485AddrWrite, address);
  });
}

const onAddressUpClick = (e) => {
  let address = parseInt(currentAddress.value) + 1;
  address = address >= 9 ? 9 : address;
  curtainDevices.value.forEach((device) => {
    mqttControl.onPublishTemp(device.controllerId, MQTTCurtainCommand.RS485AddrWrite, address);
  });  
}
</script>
