<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">조명 설정</h4>
    <!-- <p>
      영역을 추가해주세요. 아래 <code style="font-size: 100%">영역 버튼</code>을
      누르시면 해당 영역이 추가 됩니다. 추가된 영역의
      <code style="font-size: 100%">상세보기 버튼</code>을 누르시면 방 추가
      화면으로 이동할 수 있습니다.
    </p> -->
    <div class="row">
      <div class="col-xl-6">
        <div class="text-muted fs-12px mb-2"><b>조명</b></div>
        <div class="list-group mb-3">
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onSettingLightControllersClick">
            <div class="flex-fill">
              <div class="fw-600">조명 컨트롤러 등록</div>
              <div class="fs-12px text-muted">
                조명 컨트롤러를 등록할 수 있습니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onSettingLightGroupsClick">
            <div class="flex-fill">
              <div class="fw-600">조명장치 관리</div>
              <div class="fs-12px text-muted">
                조명장치를 추가/삭제할 수 있습니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onSettingBatchClick">
            <div class="flex-fill">
              <div class="fw-600">일괄 점소등 설정</div>
              <div class="fs-12px text-muted">
                영역에 대한 일괄 점등, 소등에 대한 설정을 할 수 있습니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onSettingFadeClick">
            <div class="flex-fill">
              <div class="fw-600">페이드 설정</div>
              <div class="fs-12px text-muted">
                페이드 전환 속도를 변경할 수 있습니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <!-- <ListGroupItem style="border-radius: 1.2rem;">
            <div class="flex-fill">
              <div class="fw-600">가상 버튼 설정</div>
              <div class="fs-12px text-muted">
                테스트
              </div>
            </div>
            <div @click="onSettingVirtualButtonClick">
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem> -->
        </div>

        <div class="text-muted fs-12px mb-2"><b>컨트롤러</b></div>
        <div class="list-group" style="border-radius: 1.2rem">
          <!-- <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onTransferRegistClick">
            <div class="flex-fill">
              <div class="fw-600">최초 등록</div>
              <div class="fs-12px text-muted">
                사업자 또는 시공자가 시스템을 설치할 때 최초로 장치등을
                등록합니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem> -->
          <ListGroupItem style="border-radius: 1.2rem;" @click="onTransferChangeClick">
            <div class="flex-fill">
              <div class="fw-600">와이파이 변경</div>
              <div class="fs-12px text-muted">
                설치된 지역의 Wifi가 변경되었을 때 변경된 wifi 정보를
                컨트롤러에 재설정 합니다.<br>
                <span class="text-danger">기존에 설치된 컨트롤러의 wifi 셋팅 정보를 변경할때만 사용하십시오.</span>
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <!-- <ListGroupItem style="border-radius: 1.2rem; margin-bottom: 4px;" @click="onTransferSendClick">
            <div class="flex-fill">
              <div class="fw-600">이관 하기</div>
              <div class="fs-12px text-muted">
                사업자 또는 시공자가 최초 등록을 완료하고 일반 사용자에게 설정
                데이터를 이관합니다.
              </div>
            </div>
            <div>
              <div class="form-switch">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem style="border-radius: 1.2rem;" @click="onTransferReceiveClick">
            <div class="flex-fill">
              <div class="fw-600">이관 받기</div>
              <div class="fs-12px text-muted">
                이전 사용자의 설정 데이터를 나에게 이관합니다.
              </div>
            </div>
            <div>
              <div class="form-switch h-100">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </ListGroupItem> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const router = useRouter();

const onSettingLightControllersClick = () => router.push('/settings/light/controllers');
const onSettingLightGroupsClick = () => router.push('/settings/light/groups');
const onSettingBatchClick = () => router.push('/settings/light/batch');
const onSettingFadeClick = () => router.push('/settings/light/fade');
const onTransferRegistClick = () => router.push('/settings/regist');
const onTransferChangeClick = () => router.push('/settings/light/change');
</script>
