<template>
  <div id="listWidget" class="mb-5">
    <ul class="breadcrumb">
      <li class="breadcrumb-item">{{ group?.name }}</li>
      <li class="breadcrumb-item active">조명장치 관리</li>
    </ul>
    <h4 class="mb-3">조명장치 관리</h4>
    <p>장치을 추가해주세요. 장치의 <code style="font-size: 100%">설정 모드</code>로 변경하시면 자동으로 아래에 해당 장치가 추가됩니다.
    추가된 장치 중 등록을 원하는 장치를 체크하여 <code style="font-size: 100%">등록 버튼</code>을 클릭해주세요.</p>

    <div class="flex-fill mb-1" v-if="settingModeDevices.length > 0">
      <div class="position-relative d-flex align-items-center">
          <div class="menu-search-input flex-fill" style="padding-right: 10px">
            <select class="form-control" v-model="deviceName">
              <option value="" style="display: none">장치이름을 선택해주세요.</option>
              <option v-for="index in 99" :key="index" :value="`${index}번`" :disabled="isExistIndex(index)">{{ index }}번</option>
            </select>
          </div>          
          <button class="btn btn-primary" @click="onRegistClick">등록</button>
      </div>
    </div>

    <hr class="mb-3" v-if="devices.length > 0" />

    <div class="row">
      <LightDeviceItem
        v-for="(item, index) in devices"
        :key="item._id"
        :index="index"
        :data="item"
        v-on:onDeleteClick="onDeleteClick"
        :onSelectChecked="onSelectDeviceChecked"
        :onSelectChange="onSelectDeviceChange"
        :selectedDevices="selectedDevices"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
} from 'vue';
import Axios, { DeviceApi, GroupApi, ZoneApi } from '@/api';
import {
  useRouter,
  useRoute,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
} from 'vue-router';
import { useStore } from 'vuex';
import PopDialog from '@/common/components/popup/PopDialog.vue';
import useModal from '@/common/hooks/useModal';
import LightDeviceItem from './components/LightDeviceItem.vue';
import useMqttControl from '@/common/hooks/useMqttControl';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const router = useRouter();
const route = useRoute();
const store = useStore();

const props = defineProps(['data']);
const zone = computed(() => store.getters['home/getCurrentZone']);
const group = ref(undefined);
const deviceName = ref('');
const deviceType = ref(1);
const selectedDevices = ref([]);
const forceRoute = ref(false);
// const addDeviceModal = reactive(useModal());

const settingModeDevices = computed(() => {
  return store.getters['home/getSettingModeDevices'];
});

const devices = computed(() => {
  let devices = group.value?.devices?.filter(device => device.type == 1) || [];
  devices = devices?.filter(device => !device.isVirtual) || [];
  devices = devices.concat(group.value?.devices?.filter(device => device.isVirtual) || []);
  devices = devices.concat((settingModeDevices.value) || []);
  // devices = devices.filter(device => !device.isVirtual);
  // if(settingModeDevices.value && settingModeDevices.value.length > 0){
  //   settingModeDevices.value.forEach(sd => {
  //     devices.push(sd)
  //   });
  // }
  console.log('DeviceSettingPage.conputed => devices : ', devices);
  return devices;
});

const existIndices = computed(() => {
  const regex = /[^0-9]/g;
  const result = devices?.value?.filter((item) => item.name).map((item) => parseInt(item.name.replace(regex, ""))) || []
  return result;
});

onBeforeMount(() => {
  emitter.emit('onChangeHeaderSetting', {
    title: '네트워크 설정',
    isShowMenu: false,
    isShowZone: false,
    isShowProfile: false,
  });
});
onBeforeUnmount(() => {
  emitter.emit('onChangeHeaderSetting', {
    title: '',
    isShowMenu: true,
    isShowZone: true,
    isShowProfile: true,
  });
});
onBeforeRouteLeave(async(to, from) => {
  if(to.path === '/settings/light/groups'){
    if(forceRoute.value)return true;

    emitter.emit('showPopAlert', {
      description:
        '세팅모드를 종료하시겠습니까?',
        okCallback:async() =>{
          forceRoute.value = true;
          const payload = {isEditMode:false}
          const result = await Axios.patch(
            ZoneApi.UPDATE_ZONE.replace(':id', zone.value._id),
            payload,
          );

          if (result.resultCode === '0000') {
            mqttControl.onPublishZone(zone.value._id,payload)
            store.dispatch('home/fetchAllZones');
            router.go(-1)              
          }
        },
        cancelCallback:() =>{}
    });
    return false;
  }
  return true;
})

onMounted(() => {
  requestGroup();
});

watch(group, () => {
  console.log(
    'DeviceSettingPage.watch => group.devices : ',
    group.value.devices,
  );
});
watch(settingModeDevices.value, () => {
  console.log(
    'DeviceSettingPage.watch => settingModeDevices : ',
    settingModeDevices.value,
  );
});

onBeforeRouteLeave((to, from) => {
  console.log('DeviceSettingPage,onBeforeRouteLeave => to : ', to);
  console.log('DeviceSettingPage,onBeforeRouteLeave => from : ', from);
  return true;
});

const isExistIndex = (index) => {
  const result = existIndices?.value?.filter(item => item === index) || [];
  return result.length > 0;
};

const onSelectDeviceChecked = (device) => {
  console.log(device, selectedDevices.value);
  const targetIndex = selectedDevices.value.findIndex(
    (item) =>
      item.serial == device.serial &&
      ((device.data?.port !== undefined && item.data.port == device.data.port) ||
        (device.data.serial && item.data.serial == device.data.serial)),
  );
  return targetIndex >= 0;
};

const onSelectDeviceChange = (device) => {
  const targetIndex = selectedDevices.value.findIndex(
    (item) =>
      item.serial == device.serial &&
      ((device.data.port !== undefined && item.data.port == device.data.port) ||
        (device.data.serial && item.data.serial == device.data.serial)),
  );
  if (targetIndex >= 0) {
    selectedDevices.value = selectedDevices.value.filter(
      (item, index) => index !== targetIndex,
    );
  } else {
    selectedDevices.value.push(device);
  }
  console.log(selectedDevices.value);
};

// const onAddClick = () => {
// addDeviceModal.show();
// requestAddGroup({zoneId:zone.value._id,groupName:'장치'})
// };

// const onAddConfirmClick = () => {
//   console.log(deviceName.value);
//   requestAddDevice({
//     groupId: route.params.groupId,
//     deviceName: deviceName.value,
//   });
// };
const onDeleteClick = (payload) => {
  console.log('DeviceSettingPage.onDeleteZoneClick => payload :', payload);

  const targetDevice = devices.value?.find(device => device._id == payload.deviceId);
  if (targetDevice?.isVirtual && targetDevice?.item?.onoff == 1) {
    emitter.emit('showPopAlert', {
      description: 'ON 상태의 가상버튼은 삭제할 수 없습니다.',
    });
    return;
  }

  const virtualDevices = devices.value?.filter(device => device.isVirtual);
  if (!targetDevice?.isVirtual && virtualDevices.length > 0) {
    emitter.emit('showPopAlert', {
      description: '가상버튼이 있으면 삭제할 수 없습니다.',
    });
    return;
  }

  emitter.emit('showPopConfirm', {
    title: '장치 삭제',
    description: `${payload.name} 장치를 삭제하시겠습니까?`,
    okText: '삭제',
    cancelText: '취소',
    okCallback: () => {
      requestDeleteDevice(payload.deviceId);
    },
  });
};
const onRegistClick = () => {
  if (selectedDevices.value.length === 0) {
    emitter.emit('showPopAlert', {
      description: '등록할 장치를 선택해주세요.',
    });
    return;
  }
  if (deviceName.value.length === 0) {
    emitter.emit('showPopAlert', {
      description: '장치이름을 선택해주세요.',
    });
    return;
  }
  const type = selectedDevices.value.at(0).type;
  const otherTypes = selectedDevices.value.filter((item) => item.type !== type);
  if (otherTypes.length > 0) {
    emitter.emit('showPopAlert', {
      description: '같은 타입의 장치만 선택해주세요.',
    });
    return;
  }
  const controllerId = selectedDevices.value.at(0).serial;
  const otherCtrls = selectedDevices.value.filter(
    (item) => item.serial !== controllerId,
  );
  if (otherCtrls.length > 0) {
    emitter.emit('showPopAlert', {
      description: '같은 컨트롤러의 장치만 선택해주세요.',
    });
    return;
  }

  const item = {};
  const port = selectedDevices.value.at(0).data.port;
  const serial = selectedDevices.value.at(0).data.serial;
  console.log(port, serial);
  if (port != undefined) {
    item['ports'] = selectedDevices.value.map((item) => item.data.port);
  }
  if (serial) {
    item['serial'] = selectedDevices.value.map((item) => item.data.serial);
  }

  requestAddDevice({
    controllerId: controllerId,
    name: deviceName.value,
    type: type,
    item: item,
  });
  deviceName.value = '';
};
const requestGroup = async () => {
  try {
    const result = await Axios.get(
      GroupApi.GROUP_INFO.replace(':id', route.params.groupId),
    );
    if (result.resultCode === '0000') {
      group.value = result.resultData;
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '방 상세 조회에 실패했습니다.',
    });
  }
};
const requestAddDevice = async (payload) => {
  try {
    const result = await Axios.post(
      GroupApi.REGIST_GROUP_DEVICES.replace(':id', route.params.groupId),
      payload,
    );
    if (result.resultCode === '0000') {
      // addDeviceModal.hide();
      requestGroup();

      if (selectedDevices.value.length > 0) {
        const devices = [...selectedDevices.value];
        devices.forEach((device) =>
          store.dispatch('home/removeSettingModeDevice', device),
        );
        selectedDevices.value = [];
      }
      store.commit('home/addDevice', {
        zoneId: parseInt(zone.value._id),
        groupId: parseInt(route.params.groupId),
        data: result.resultData.device,
      });
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (e) {
    console.log(e);
    emitter.emit('showPopAlert', {
      description: '장치 추가중 오류가 발생하였습니다.',
    });
  }
};
const requestDeleteDevice = async (deviceId) => {
  try {
    const result = await Axios.delete(
      DeviceApi.DELETE_DEVICE.replace(':id', deviceId),
    );
    if (result.resultCode === '0000') {
      requestGroup();
      store.commit('home/deleteDevice', {
        zoneId: parseInt(zone.value._id),
        groupId: parseInt(route.params.groupId),
        deviceId,
      });
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (error) {
    console.log(error)
    emitter.emit('showPopAlert', {
      description: '장치 삭제중 오류가 발생하였습니다.',
    });
  }
};
</script>
