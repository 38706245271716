<template>
  <PopDialog2
    title="공지사항"
    :visible="props.visible"
    :cancelCallback="onCancelClick"
  >
    <div class="md-layout">
      <div class="md-layout-item md-size-50 mx-auto">
        <label class="title mb-4 font-weight-bold text-center" style="font-size: 1rem">
          {{ props.data.title }}
        </label>
        <div class="html-wrapper" v-html="props.data.content" />

        <hr class="mb-3" style="margin-left: -16px; margin-right: -16px;" />

        <div class="text-end">
          <input
            class="form-check-input"            
            type="checkbox"
            id="customCheck1"
            @change="onNeverSeeChange"
          />
          <label class="ms-2 mb-0" style="color: #666666;" for="customCheck1">
            다시 보지 않기
          </label>
        </div>
      </div>
    </div>
  </PopDialog2>
</template>

<script setup>
import { ref, defineProps, computed, getCurrentInstance, reactive, onBeforeMount, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { DeviceType, PowerState } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';
import PopDialog2 from '@/common/components/popup/PopDialog2.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import useMobile from '@/common/hooks/useMobile';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const { getLocalStorage, setLocalStorage } = useMobile();
const store = useStore();
const router = useRouter();
const props = defineProps([
  'visible',
  'cancelCallback',
  'data',
]);

onMounted(() => {
  const elem = document.querySelector(".html-wrapper");
  const images = elem.querySelectorAll("img");
  if (images.length > 0) {
    images.forEach((image) => {
      image.style.width = "100%";
    });
  }
});

const onNeverSeeChange = async (e) => {
  try {
    await setLocalStorage(`notice${props.data._id}`, true);
  } catch(e) {
    console.log(e);
  } finally {
    if (props.cancelCallback) {
      props.cancelCallback();
    }
  }
}

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};
</script>

<style scoped>
.html-img {
  width: 100%;
}
</style>