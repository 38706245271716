<template>
  <div class="col" :style="props.itemStyle || ''">
    <div class="list-group" style="border-radius: 1.2rem;" @click="onCurtainPowerClicked" v-touch:press="onTouchDown" v-touch:drag="onTouchDrag" v-touch:release="onTouchRelease" v-touch:hold="onTouchHold">
      <div class="list-group-item d-flex align-items-center" :style="`height: 4rem; padding: 2px 0px; background: ${getPower ? '#ffe082' : 'inherits'};`">
      <!-- <div class="list-group-item d-flex align-items-center" :style="`height: 4rem; padding: 2px 0px; background: ${curtainStatus == 1 ? '#ffe082' : (curtainStatus == 0 ? 'inherits' : '#fff8e1')};`"> -->
        <img :src="require('@/assets/images/curtain.svg')" style="margin-right: 0.4rem; height: 13px; width: 13px; margin-top: -2px;" class="ms-3 me-2" />
        <div class="flex-fill" style="font-size: 0.8rem">
          커튼
          <br>
          {{ currentStateText }}
        </div>
        <div class="mx-1 h-100" @click.stop="onSettingClicked">
          <i :class="`fa-solid fa-gear me-3 mt-4`" style="font-size: 0.8rem" />
        </div>
      </div>
    </div>
    <!-- <transition name="fade">
      <div v-if="isExpand">
        <RoomCurtainDeviceItem v-for="device in curtainDevices" :key="device._id" :roomName="props.roomName" :roomId="props.roomId" :deviceId="device._id" />
      </div>
    </transition> -->
  </div>
        
  <CurtainItemModal
    v-if="curtainItemModal.visible"
    :visible="curtainItemModal.visible"
    :cancelCallback="curtainItemModal.hide"
    :roomName="props.roomName"
    :roomId="props.roomId"
    :handlePowerClick="onCurtainPowerClicked"
    :curtainStatus="curtainStatus"
    :afterStatus="afterStatus"
  />
</template>

<script setup>
import { reactive, defineProps, getCurrentInstance, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { DeviceType, PowerState, MQTTTempCommand, MQTTCurtainCommand } from '@/common/constants';
import CurtainItemModal from './CurtainItemModal.vue';
import useModal from '@/common/hooks/useModal';
import useMqttControl from '@/common/hooks/useMqttControl';
import useDeviceTouch from '@/common/hooks/useDeviceTouch';

const { emit } = getCurrentInstance();
const mqttControl = reactive(useMqttControl());
const props = defineProps(['roomId', 'roomName']);
const store = useStore();
const curtainItemModal = reactive(useModal());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const { 
  handlePower, 
  handleTouchDown, 
  handleTouchDrag, 
  handleTouchRelease,
  handleTouchHold
} = useDeviceTouch(props.roomId);
const curtainStatus = ref(1);
const afterStatus = ref(0);
const timeoutId = ref(0);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});

const curtainDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 3,
  );
});

const getPower = computed(() => {
  return curtainDevices.value.some((d) => d.item?.close === PowerState.On);
  // return curtainStatus.value === 1 || afterStatus.value === 1 ? true : false;
});

const currentStateText = computed(() => {
  return getPower.value ? "닫힘" : "열림";
  // return curtainStatus.value === 1 ? "닫힘" : (curtainStatus.value === 0 ? "열림" : (afterStatus.value === 1 ? "닫는 중" : "여는 중"));
})

const deviceState = (type) => {
  return devices.value
    .filter((d) => d.type === type)
    .some((d) => d.item.close === PowerState.On);
  // return curtainStatus.value === 1 || afterStatus.value === 1 ? true : false;
};

const onCurtainPowerClicked = (e) => {
  let command = undefined;
  if (getPower.value) {
    command = MQTTCurtainCommand.IsClose;
  } else {
    command = MQTTCurtainCommand.IsOpen;
  }
  // if (curtainStatus.value === 1) {
  //   curtainStatus.value = -1;
  //   afterStatus.value = 0;
  //   timeoutId.value = setTimeout(() => curtainStatus.value = afterStatus.value, 3000);
  //   command = MQTTCurtainCommand.IsClose;
  // } else if (curtainStatus.value === 0) {
  //   curtainStatus.value = -1;
  //   afterStatus.value = 1;
  //   timeoutId.value = setTimeout(() => curtainStatus.value = afterStatus.value, 3000);
  //   command = MQTTCurtainCommand.IsOpen;
  // } else {
  //   clearTimeout(timeoutId.value);
  //   curtainStatus.value = afterStatus.value;
  //   command = MQTTCurtainCommand.IsStop;
  // }

  // handlePower(() => {
  //   // if (zone.value.isEditMode) {
  //   //   emitter.emit('showPopAlert', {
  //   //     description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
  //   //   });
  //   //   return;
  //   // }
  //   emit('onPowerClicked', {
  //     id: props.roomId,
  //     type: 3,
  //     close: getPower.value ? PowerState.Off : PowerState.On,
  //   });
  // });

  curtainDevices.value.forEach((device) => {
    mqttControl.onPublishTemp(device.controllerId, command, 1);
  });
};

const onSettingClicked = (e) => {
  curtainItemModal.show();
}

const onTouchDown = (e) => {
  if (deviceState(2)) {
    handleTouchDown(e);
  }
}

const onTouchDrag = (e) => {
  handleTouchDrag(e, (screenX, startX) => {
    // percent.value = percent.value + (screenX - startX) / boilerItem.value.clientWidth * 100.0;
    // percent.value = percent.value <= 0 ? 0 : percent.value;
    // percent.value = percent.value >= 100 ? 100 : percent.value;
    // targetTemp.value = parseInt(percent.value  * 0.27) + 9;
  });
}

const onTouchRelease = (e) => {
  handleTouchRelease(e, () => {});
}

const onTouchHold = (e) => {
  handleTouchHold(e, onSettingClicked);
}
</script>