<script setup>
import {
  ref,
  computed,
  reactive,
  defineProps,
  watchEffect,
  watch,
  getCurrentInstance,
  onMounted,
  onBeforeUnmount
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { RouterLink } from 'vue-router';
import useMqttControl from '@/common/hooks/useMqttControl';

const mqttControl = reactive(useMqttControl());
const route = useRoute();
const router = useRouter();
const store = useStore();
const getters = computed(() => store.getters);
const { emitter, mobile, mqtt } =
  getCurrentInstance().appContext.config.globalProperties;
;
const mqttSubscribed = computed(() => mqtt.isSubscribed());
const mqttConnected = computed(() => mqtt.isConnected());

watch(mqttConnected, async () => {
  if (mqttConnected.value == false) {
    // let intervalCount = 0;
    // const intervalId = setInterval(async () => {
    //   if (mqttConnected.value || intervalCount >= 20) {
    //     clearInterval(intervalId);
    //     return;
    //   }

    //   mqttControl.connectAuth();
    //   console.log("try connect auth...")
    //   intervalCount++;
    // }, 5000);
  }
})

const currentZone = computed({
  get: () => {
    return store.getters['home/getCurrent'].zoneId;
  },

  set: (value) => {
    store.commit('home/setCurrentZone', value);
  },
});

const zones = computed(() => {
  return store.getters['home/getZones'];
});

const getCurrentZone = computed(() => {
  return store.getters['home/getCurrentZone'];
});

const getCurrentControllers = computed(() => {
  return getCurrentZone.value?.controllers || [];
});

const isHome = computed(() => {
  return route.path === '/rooms' || route.path === '/rooms/';
});

const isOwner = computed(() => {
  return getCurrentZone.value.isOwner;
});

const title = ref('');
const isShowZone = ref(true);
const isShowMenu = ref(true);
const isShowProfile = ref(true);


let topics = [];

onMounted(async () => {
  await subscribe();
  emitter.on('onChangeHeaderSetting', (params) => {
    // console.log('Header.onMounted => onChangeHeaderSetting : ', params);
    if (params.title != undefined) title.value = params.title;
    if (params.isShowZone != undefined) isShowZone.value = params.isShowZone;
    if (params.isShowMenu != undefined) isShowMenu.value = params.isShowMenu;
    if (params.isShowProfile != undefined)
      isShowProfile.value = params.isShowProfile;
  });
  console.log('Header.onMounted => mqtt isConnected : ', mqttControl.isConnected());  

  // mqttControl.registerEvent('on-connect', async(topic,message) => {
  //   console.log("mqtt event: on-connect");
  //   await unSubscribe(topics)    
  //   await subscribe()
  // })
});

onBeforeUnmount(() => {
  console.log('Header.onBeforeUnmount');
  mqttControl.unRegisterEvent('on-connect');
  mqttControl.unRegisterEvent('on-disconnect');
  mqttControl.unRegisterEvent('on-reconnect');
  mqttControl.unRegisterEvent('on-connect-fail');
});

watch(mqttConnected, async () => {
  if (mqttConnected.value) {
    await unSubscribe(topics);
    await subscribe();
  }
});

watch(currentZone, async () => {

  console.log('Header.watch - currentZone');
  await subscribe();

  if (route.path.includes('/rooms/')) {
    // 영역이 변경된 경우 home으로 이동함.
    router.push('/rooms');
  } else if (route.path.includes('/devices/')) {
    // 영역이 변경된 경우 home으로 이동함.
    router.push('/devices');
  } else if (route.path.includes('/schedules/')) {
    // 영역이 변경된 경우 home으로 이동함.
    router.push('/schedules');
  }
});

watch(getCurrentControllers, async () => {
  console.log('Header.watch - getCurrentControllers');  
  await subscribe();
});

const getTopics = (() => {
  const tempTopics = []
  zones.value.forEach((z) => {
    tempTopics.push(
        '$' +
          process.env.VUE_APP_MQTT_DEFAULT_TOPIC_PREFIX +
          '/' +
          process.env.VUE_APP_MQTT_ZONE_TOPIC_PREFIX +
          `/${z._id}/${process.env.VUE_APP_MQTT_PUBLISH_TOPIC_SIRFIX}`,
      );
    });

  zones.value.forEach((z) => {
    let controllers = z.controllers;

    if (controllers && controllers.length > 0) {
      controllers.forEach((c) => {
      tempTopics.push(
          '$' +
            process.env.VUE_APP_MQTT_DEFAULT_TOPIC_PREFIX +
            '/' +
            process.env.VUE_APP_MQTT_MCS_TOPIC_PREFIX +
            `/${c._id}/${process.env.VUE_APP_MQTT_SUBSCRIBE_TOPIC_SIRFIX}`,
        );
      });
    }
  });

  zones.value.forEach((z) => {
    z.groups.forEach((group) => {
      const boilerDevices = group.devices.filter(device => device.type == 2);
      boilerDevices.forEach(device => tempTopics.push(`stat/inshow/${device.controllerId}/#`));
    });
  });  

  if(process.env.VUE_APP_ENABLE_TEMP_CONTROLLER){
    // tempTopics.push(process.env.VUE_APP_MQTT_TEMP_TOPIC_PREFIX+'/#');
    // tempTopics.push(process.env.VUE_APP_MQTT_TEPM_TOPIC_PREFIX_STATE+'/#');
  }
  return tempTopics;
})

const subscribe = async () => {
  // console.log("Header.subscribe => request subscribe~");
    
  const newTopics = getTopics();
  
  if(newTopics.length == 0) return;

  const difference = newTopics?.filter((element) => !topics.includes(element));
  if(newTopics.length != topics.length || difference?.length > 0){    
    if(topics.length > 0)await unSubscribe();    

    // console.log("Header.subscribe => newTopics => ",newTopics);
    // console.log("Header.subscribe => topics => ",topics);
    // console.log("Header.subscribe => subscribe diff => ",difference);

    //topics = newTopics

    topics = [...newTopics] // 보일러용 mqtt topic 기본 추가
    return mqttControl.subscribe(topics);
  }
};

const unSubscribe = async () => {
  if(topics.length == 0) return;
  await mqttControl.unSubscribe(topics);  
  topics = []
  return
};

function toggleAppSidebarMinify() {
  store.commit('setAppSidebarMinified', !getters.value.appSidebarMinified);
}
function toggleAppSidebarMobileToggled() {
  store.commit(
    'setAppSidebarMobileToggled',
    !getters.value.appSidebarMobileToggled,
  );
}
function toggleAppHeaderSearch(event) {
  event.preventDefault();

  store.commit(
    'setAppHeaderSearchToggled',
    !getters.value.appHeaderSearchToggled,
  );
}
function checkForm(event) {
  event.preventDefault();
  this.$router.push({ path: '/extra/search' });
}

function onBackClick() {
  router.go(-1);
}

const onRefreshClick = async () => {
  // await store.dispatch('home/fetchAllZones');
  getCurrentZone.value.controllers.forEach(controller => {
    mqttControl.onPublishController(controller._id, {
      serial: controller._id,
      cmd: "q",
      type: 1
    });
  });
}

function onLogoutClick() {
  emitter.emit('showPopConfirm', {
    title: '로그아웃',
    description: `정말 로그아웃 하시겠습니까?`,
    okCallback: () => {
      emitter.on('onLogoutResult', () => {
        emitter.off('onLogoutResult');
      });
      mobile.logout(JSON.stringify({ callback: 'onLogoutResult' }));
      store.dispatch('logout');
      router.push('/login');
    },
  });
}

// const onTest = async () => {
//   console.log("on test !!!!!!!!!!!!!!!!!!!!!")
//   console.log(mqttControl.isConnected())
//   console.log(mqttConnected.value)

//   if (mqttControl.isConnected()) {
//     mqtt.disconnect();
//   } else {
//     mqtt.connect();
//   }
// }
</script>

<template>
  <div id="header" class="app-header">
    <!-- BEGIN mobile-toggler -->
    <div class="mobile-toggler" v-if="!isHome">
      <button type="button" class="menu-toggler" @click="onBackClick">
        <i class="fa-solid fa-chevron-left fa-xl"></i>
      </button>
    </div>
    <div class="mobile-toggler" v-if="isShowMenu">
      <button
        type="button"
        class="menu-toggler"
        v-on:click="toggleAppSidebarMobileToggled"
      >
        <span class="bar"></span>
        <span class="bar"></span>
      </button>
    </div>
    <!-- END mobile-toggler -->
    <!-- getCurrentZone:{{getCurrentZone._id}} -->
    <!-- BEGIN brand -->
    <div class="brand">
      <div class="desktop-toggler" style="width: 40px" v-if="!isHome">
        <button
          type="button"
          class="menu-toggler"
          style="padding-right: 0"
          @click="onBackClick"
        >
          <i class="fa-solid fa-chevron-left fa-xl"></i>
        </button>
      </div>

      <div class="desktop-toggler">
        <button
          type="button"
          class="menu-toggler"
          v-on:click="toggleAppSidebarMinify"
        >
          <span class="bar"></span>
          <span class="bar"></span>
        </button>
      </div>

      <a class="brand-logo">
        <img
          :src="require('@/assets/images/inshow_logo.png')"
          alt=""
          height="20"
          :style="`padding-left: ${isHome ? 0 : 0}rem`"
        />
      </a>
    </div>
    <!-- END brand -->

    <!-- BEGIN menu -->
    <div class="menu">
      <form
        class="menu-search"
        name="header_search_form"
        v-on:submit="checkForm"
      >
        <div v-if="title">
          <h4 style="margin-top: 9px">{{ title }}</h4>
        </div>
        <div v-if="isShowZone">
          <div class="menu-search-icon" v-if="zones && zones.length > 0">
            <!-- <i class="fa-solid fa-house"></i> -->
            <img
              :src="
                isOwner
                  ? require('@/assets/images/house-owner.png')
                  : require('@/assets/images/house-shared.png')
              "
              style="max-height: 23px; margin-right: 5px"
            />
          </div>

          <div class="menu-search-input" v-if="zones && zones.length > 0">
            <select class="form-control" v-model="currentZone">
              <option
                v-for="item in zones"
                :value="item._id"
                :key="item._id"
                :title="
                  item.isOwner
                    ? require('@/assets/images/house-owner.png')
                    : require('@/assets/images/house-shared.png')
                "
              >
              {{ item.name }}{{item.isOwner?'':' (Share)'}}
                <!-- {{ item.name }} -->
              </option>
            </select>
          </div>
        </div>
      </form>
      <div class="menu-item dropdown" v-if="isShowProfile">
        <a data-bs-toggle="dropdown" data-display="static" class="menu-link">
          <div class="menu-img">
            <img
              :src="require('@/assets/images/user.png')"
              alt=""
              class="ms-100 mh-100 rounded-circle"
            />
          <span v-if="!mqttConnected" class="position-absolute translate-middle badge rounded-pill bg-danger" style="top: 10%; left: 90%;">!</span>
          <span v-else-if="!mqttSubscribed" class="position-absolute translate-middle badge rounded-pill bg-warning" style="top: 10%; left: 90%;">!</span>
          </div>
          <div class="menu-text">{{ getters.account?.nickName }}</div>
        </a>
        <div
          class="dropdown-menu dropdown-menu-end me-lg-3"
          @click.prevent="() => {}"
        >
          <RouterLink
            to="/profile"
            class="dropdown-item d-flex align-items-center"
            >프로필
            <i class="fa fa-user-circle fa-fw ms-auto text-gray-400 fs-16px"></i
          ></RouterLink>
          <RouterLink
            to="/share"
            class="dropdown-item d-flex align-items-center"
            >공유
            <i
              class="fa-solid fa-share-from-square fa-fw ms-auto text-gray-400 fs-16px"
            ></i
          ></RouterLink>
          <div class="dropdown-divider"></div>
          <div
            class="dropdown-item d-flex align-items-center"
            @click="onRefreshClick"
          >
            새로고침
            <i class="fa fa-arrows-rotate fa-fw ms-auto text-gray-400 fs-16px"></i>
          </div>
          <div class="dropdown-divider"></div>
          <div
            class="dropdown-item d-flex align-items-center"
            @click="onLogoutClick"
          >
            로그아웃
            <i class="fa fa-toggle-off fa-fw ms-auto text-gray-400 fs-16px"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- END menu -->
  </div>
</template>
