<template>
  <div class="col-md-6 mb-2">
    <div class="list-group" style="border-radius: 1.2rem">
      <ListGroupItem
      >
        <div class="flex-1 text-break" v-touch:hold="onTouchHold">
          <div style="font-size: 1rem">{{ props.data.name }}</div>
        </div>
        <div @click.stop="onDetailClick">
          <i class="fa fa-chevron-right text-green"></i>
        </div>
      </ListGroupItem>
      <ListGroupItem
        class="text-gray-800"
        style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
        v-if="devicesText.length > 0"
      >
        조명장치 : {{ devicesText }}
      </ListGroupItem>

      <!-- <ListGroupItem
        style="padding-top: 0.2rem; padding-bottom: 0.2rem;"
      >
        <div class="flex-fill" @click="onDetailClick">
          <div
            class="fw-400 text-green text-end me-2"
            style="font-size: 0.8rem"
          >
            상세보기
          </div>
        </div>
        <div>
          <i
            class="fa fa-chevron-right text-green"
            style="font-size: 0.8rem"
          ></i>
        </div>
      </ListGroupItem> -->
    </div>
    <RoomItemModal
      v-if="groupModal.visible"
      :visible="groupModal.visible"
      :okCallback="onUpdateGroupName"
      :cancelCallback="groupModal.hide"
      :roomName="props.data?.name || ''"
    />
  </div>
</template>

<script setup>
import { onMounted, defineProps, getCurrentInstance, computed, reactive } from 'vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import RoomItemModal from '@/views/rooms/components/RoomItemModal.vue';
import useModal from '@/common/hooks/useModal';
import Axios, { GroupApi, ZoneApi } from '@/api';
import useMqttControl from '@/common/hooks/useMqttControl';

const mqttControl = reactive(useMqttControl());
const { emit } = getCurrentInstance();
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const router = useRouter();
const route = useRoute();
const props = defineProps(['index', 'data', 'onGroupUpdated']);
const store = useStore();
const groupModal = reactive(useModal());

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const backColor = computed(() => {
  if (props.index % 5 === 0) {
    return 'bg-gradient-custom-orange';
  } else if (props.index % 5 === 1) {
    return 'bg-gradient-custom-pink';
  } else if (props.index % 5 === 2) {
    return 'bg-gradient-custom-teal';
  } else if (props.index % 5 === 3) {
    return 'bg-gradient-custom-indigo';
  } else {
    return 'bg-gradient-custom-blue';
  }
});
const devicesText = computed(() => {
  let text = "";
  props.data?.devices?.filter(device => device.type == 1)
    .forEach(device => text += text.length > 0 ? ", " + device.name : device.name);
  return text;
})

onMounted(() => {
  console.log('ZoneItem.onMounted().props =>', props);
});

const onDetailClick = () => {
  if(!zone.value.isEditMode){
    emitter.emit('showPopAlert', {
      title: '세팅 모드',
      description: `세팅모드 화면으로 이동하시겠습니까?\n세팅모드 활성화시 모바일 기기에서 컨트롤이 제한됩니다.`,
      okCallback: async() => {
        const payload = {isEditMode:true}
        const result = await Axios.patch(
          ZoneApi.UPDATE_ZONE.replace(':id', zone.value._id),
          payload,
        );
        console.log(result);
        if (result.resultCode === '0000') {
          mqttControl.onPublishZone(zone.value._id, payload);
          router.push(`/settings/light/groups/${props.data._id}/devices`);
        }
      },
      cancelCallback:() =>{}
    });
    return;
  }

  router.push(`/settings/light/groups/${props.data._id}/devices`);
};

const onDeleteClick = () => {
  console.log('GroupItem.onDeleteClick =>', {
    name: props.data.name,
    groupId: props.data._id,
  });
  emit('onDeleteClick', { name: props.data.name, groupId: props.data._id });
};

const onTouchHold = () => {
  groupModal.show();
}

const onUpdateGroupName = (value) => {
  requestUpdateGroup({
    name: value,
  });
  groupModal.hide();
};

const requestUpdateGroup = async (params) => {
  try {
    const result = await Axios.patch(
      GroupApi.UPDATE_GROUP.replace(':id', props.data._id),
      params,
    );
    if (result.resultCode === '0000') {
      store.commit('home/updateGroup', {
        id: props.data._id,
        data: params,
      });
      emit('onGroupUpdated');
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '방 정보 수정에 실패했습니다.',
    });
  }
};
</script>
