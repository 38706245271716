<template>
  <div id="listWidget" class="mb-4">
    <div>
      <h4 class="mb-3">초기화하기</h4>
      <p class="mb-0">
        1. 초기화 할 영역을 선택해주세요.
      </p>
    </div>

    <hr class="mb-3" />

    <div class="row">
      <div class="col-xl-6 mb-3">
        <div class="text-muted fs-12px mb-2"><b>초기화 영역 선택</b></div>
        <div class="list-group" style="border-radius: 1.2rem">
          <ListGroupItem
            style="border-radius: 1.2rem; margin-bottom: 4px;"
            v-for="(zone, index) in zones"
            :key="zone._id"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :id="`gridCheck${index}`"
                :value="zone._id"
                @change="onSelectZoneChange"
              />
              <label class="form-check-label" :for="`gridCheck${index}`">
                {{ zone.name }}
              </label>
            </div>
          </ListGroupItem>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 mb-0">
        <div class="text-muted fs-12px mb-2"><b>초기화 여부</b></div>
        <div class="list-group" style="border-radius: 1.2rem">
          <ListGroupItem
            style="border-radius: 1.2rem; margin-bottom: 4px;"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="confirmCheck"
                @change="onConfirmCheckChange"
              />
              <label class="form-check-label text-danger" for="confirmCheck">
                정말 초기화 하시겠습니까?
              </label>
            </div>
          </ListGroupItem>
        </div>
      </div>
    </div>
  </div>

  <div id="footer" class="app-footer ms-0">
    <footer class="mt-auto">
      <div class="row">
        <div class="col" />
        <div class="col" />
        <div class="col">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            style="width: 100%"
            @click="onInitZoneClick"
          >
            초기화
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { DeviceDefault } from '@/common/constants';
import Axios, { CtrlApi, ZoneApi } from '@/api';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const store = useStore();
const router = useRouter();
const { emitter, mobile, logger } =
  getCurrentInstance().appContext.config.globalProperties;

const sharedCode = ref('');
const selectedZones = ref([]);
const confirmCheck = ref(false);
const account = computed(() => store.getters['account']);
const zones = computed(() => store.getters['home/getZones']);

const onSelectZoneChange = (e) => {
  const targetIndex = selectedZones.value.findIndex(
    (item) => item == e.target.value,
  );
  if (targetIndex >= 0) {
    selectedZones.value = selectedZones.value.filter(
      (item) => item != e.target.value,
    );
  } else {
    selectedZones.value.push(e.target.value);
  }
};

const onConfirmCheckChange = (e) => {
  confirmCheck.value = e.target.checked;
}

const onInitZoneClick = async () => {
  if (!confirmCheck.value) {
    emitter.emit('showPopAlert', {
      description: '초기화 확인 여부를 체크해주세요.',
    });
    return;
  }
  if (selectedZones.value.length === 0) {
    emitter.emit('showPopAlert', {
      description: '초기화 영역을 선택해주세요.',
    });
    return;
  }

  emitter.emit('showPopConfirm', {
    description: '다시 복구할 수 없습니다.\n정말 초기화 하시겠습니까?',
    okCallback: () => {
      console.log(selectedZones.value);
    },
  });

  // try {
  //   await requestInitZones({
  //     userId: account.value._id,
  //     sharedCode: sharedCode.value,
  //     zones: selectedZones.value,
  //   });
  //   await store.dispatch('home/fetchAllZones');

  //   emitter.emit('showPopAlert', {
  //     description: '이관이 완료되었습니다.',
  //     okCallback: () => router.go(-1),
  //   });
  // } catch (e) {
  //   emitter.emit('showPopAlert', {
  //     description: e,
  //   });
  // }
};

const requestTransferSend = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      // logger.log(
      //   `TransferSendPage.requestTransferSend => params : ${JSON.stringify(
      //     params,
      //   )}`,
      // );
      const result = await Axios.patch(
        ZoneApi.INIT_ZONE.replace(':id', props.data._id),
        params,
      );

      if (result.resultCode === '0000') {
        // logger.log(
        //   `TransferSendPage.requestTransferSend => result : ${JSON.stringify(
        //     result,
        //   )}`,
        // );
        resolve(result);
        return;
      }

      reject(result.resultMessage);
    } catch (e) {
      console.log(e);
      reject('컨트롤러 이관 중 오류가 발생하였습니다.');
    }
  });
};
</script>
