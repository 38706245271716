<template>
  <div class="col-md-6 mb-3">
    <div class="list-group" style="border-radius: 1.2rem">
      <ListGroupItem
        style="padding-top: 0.5rem; padding-bottom: 0.5rem;"
      >
        <div class="flex-1 text-break">
          <div style="font-size: 1rem" v-if="deviceData._id" v-touch:hold="onTouchHold">
            {{ deviceData.name }}
          </div>

          <div class="text-gray-600" v-if="deviceData._id">
            {{ getControllerId(deviceData) }}
          </div>

          <div class="form-check" v-else>
            <input
              class="form-check-input"
              type="checkbox"
              :id="`gridCheck${props.index}`"
              :checked="onSelectChecked(deviceData)"
              @change="() => onSelectChange(deviceData)"
            />
            <label class="form-check-label" :for="`gridCheck${props.index}`">
              {{ getControllerId(deviceData) }}
            </label>
          </div>
        </div>
        <div v-if="deviceData._id" @click.stop="onDeleteClick">
          <i class="fa-solid fa-x"></i>
        </div>
      </ListGroupItem>

      <ListGroupItem
        class="text-gray-800"
        style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
        v-if="deviceData.type == DeviceType.Lamp"
      >
        {{ getInfo(deviceData) }}
      </ListGroupItem>

      <!-- <div class="list-group-item d-flex align-items-center text-gray-800">
        {{ deviceData }}
      </div> -->
    </div>
  </div>
  <DeviceItemModal
    v-if="deviceItemModal.visible"
    :visible="deviceItemModal.visible"
    :okCallback="onUpdateDeviceName"
    :cancelCallback="deviceItemModal.hide"
    :deviceName="deviceData.name"
    :groupId="route.params.groupId"
  />
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  watch,
} from 'vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { DeviceType } from '@/common/constants';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import DeviceItemModal from '@/views/rooms/components/DeviceItemModal.vue';
import useModal from '@/common/hooks/useModal';
import Axios, { DeviceApi } from '@/api';

const { emit } = getCurrentInstance();
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const router = useRouter();
const route = useRoute();
const props = defineProps([
  'index',
  'data',
  'onSelectChecked',
  'onSelectChange',
]);
const deviceItemModal = reactive(useModal());
const store = useStore();
const backColor = computed(() => {
  if (props.index % 5 === 0) {
    return 'bg-gradient-custom-orange';
  } else if (props.index % 5 === 1) {
    return 'bg-gradient-custom-pink';
  } else if (props.index % 5 === 2) {
    return 'bg-gradient-custom-teal';
  } else if (props.index % 5 === 3) {
    return 'bg-gradient-custom-indigo';
  } else {
    return 'bg-gradient-custom-blue';
  }
});

const deviceData = ref(props.data);

onMounted(() => {
  console.log('DeviceItem.onMounted().props =>', props);
});

const getInfo = (data) => {
  if (data.isVirtual) {
    return "가상 버튼";
  }
  if (data.type == DeviceType.Lamp)
    return `연결포트 : ${data._id ? data.item.ports : data.data.port}`;
  else if (data.type == DeviceType.Boiler)
    return `시리얼번호 : ${data._id ? data.item.serial : data.data.serial}`;
  else if (data.type == DeviceType.Curtain)
    return `시리얼번호 : ${data._id ? data.item.serial : data.data.serial}`;
  else if (data.type == DeviceType.Ventilator)
    return `시리얼번호 : ${data._id ? data.item.serial : data.data.serial}`;
};

const getType = (data) => {
  if (data.type == DeviceType.Lamp) return '조명';
  else if (data.type == DeviceType.Boiler) return '보일러';
  else if (data.type == DeviceType.Curtain) return '커튼';
  else if (data.type == DeviceType.Ventilator) return '환풍기';
};

const getDeviceType = (type) => {
  switch (type) {
    case DeviceType.Lamp:
      return '조명';
    case DeviceType.Boiler:
      return '보일러';
    case DeviceType.Curtain:
      return '커튼';
    case DeviceType.Ventilator:
      return '환풍기';
  }
};

const getControllerId = (data) => {
  return data._id ? data.controllerId : data.serial;
};

const onDetailClick = () => {
  console.log('DeviceItem.onDetailClick');
  // store.commit("home/setCurrentGroup",props.roomId )

  // router.push(`/rooms/${props.roomId}`);
};

const onDeleteClick = () => {
  console.log('DeviceItem.onDeleteClick =>', {
    name: deviceData.value.name,
    deviceId: deviceData.value._id,
  });
  emit('onDeleteClick', { name: deviceData.value.name, deviceId: deviceData.value._id });
};

const onTouchHold = () => {
  deviceItemModal.show();
}

const onUpdateDeviceName = (value) => {
  requestUpdateDevice({
    name: value
  });
  deviceItemModal.hide();
};

const requestUpdateDevice = async (params) => {
  try {
    const result = await Axios.patch(
      DeviceApi.UPDATE_DEVICE.replace(':id', deviceData.value._id),
      params,
    );
    if (result.resultCode === '0000') {
      console.log(result.resultData);
      store.commit('home/updateDevice', {
        groupId: route.params.groupId,
        id: deviceData.value._id,
        data: params,
      });
      deviceData.value = {
        ...deviceData.value,
        ...params
      };
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '장치 정보 수정에 실패했습니다.',
    });
  }
};
</script>
