import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import mqtt from './common/mqtt';
import Vue3TouchEvents from 'vue3-touch-events';
import { Vue3ProgressPlugin } from '@marcoschulte/vue3-progress';
import logger from './common/logger';
import mobile from './common/mobile';

import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/scss/styles.scss';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import '@marcoschulte/vue3-progress/dist/index.css';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/v4-shims.scss';
import '@marcoschulte/vue3-progress/dist/';

// mqtt
import mqttVueHook, { useMQTT } from 'mqtt-vue-hook';
import mitt from 'mitt';

console.log('process.env.VUE_APP_DOMAIN:', process.env.VUE_APP_DOMAIN);
console.log('process.env.VUE_APP_PROXY_DOMAIN:', process.env.VUE_APP_PROXY_DOMAIN);
console.log('process.env.VUE_APP_MQTT_DOMAIN:', process.env.VUE_APP_MQTT_DOMAIN);

const emitter = mitt();
const app = createApp(App);
app.use(store);
app.use(router);

app.use(mqttVueHook, process.env.VUE_APP_MQTT_DOMAIN, {
  clean: false,
  keepalive: 60,
  clientId: `${process.env.VUE_APP_MQTT_CLIENT_ID}_${Math.random()
    .toString(16)
    .substring(2, 10)}`,
  connectTimeout: 4000,
  rejectUnauthorized : false
});


app.config.globalProperties.emitter = emitter;

app.use(logger);
app.use(mqtt);
app.use(mobile);
app.use(Vue3ProgressPlugin);
app.use(Vue3TouchEvents);

app.mount('#app');
