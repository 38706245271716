<template>
  <div id="listWidget" class="mb-4">
    <div>
      <h4 class="mb-3">온도조절기 스캔</h4>
      <p class="mb-0">
        1. 제품 빨간색 LED가 빠르게 깜빡이는 상태를 확인헤주세요.
      </p>
      <p class="mb-0">
        2. 그리고 제품에 있는 QR 코드를 스캔해주세요.
      </p>
    </div>

    <hr class="mb-3" />
    
    <!-- <canvas id="canvas" /> -->

    <button
      type="button"
      class="btn btn-primary mb-3 me-1"
      style="min-width: 100%;"
      @click="onQRCodeRegistClick"
    >
      QR 코드 촬영
    </button>

    <div class="row">
      <div class="col-xl-6 mb-3">
        <div class="list-group" style="border-radius: 1.2rem">
          <div class="list-group-item" style="border-radius: 1.2rem;">
            <input
              type="text"
              class="form-control form-control-lg fs-15px mb-2 col"
              placeholder="제품 시리얼 코드를 입력해주세요."
              v-model="serialCode"
            />
            <button
              type="button"
              class="btn btn-outline-primary mb-1 me-1"
              style="min-width: 100%;"
              @click="onRegistClick"
            >
              제품 등록
            </button>
            <!-- <button
              type="button"
              class="btn btn-outline-primary mt-5 mb-1 me-1"
              style="min-width: 100%;"
              @click="test"
            >
              테스트
            </button>
            <button
              type="button"
              class="btn btn-outline-primary mt-5 mb-1 me-1"
              style="min-width: 100%;"
              @click="test2"
            >
              테스트2
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  reactive,
} from 'vue';
import QRCode from 'qrcode';
import Axios, { DeviceApi } from '@/api';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useMobile from '@/common/hooks/useMobile';
import useMqttControl from '@/common/hooks/useMqttControl';
import { isAndroid, isIOS } from 'mobile-device-detect';
import { useMQTT } from 'mqtt-vue-hook';

const mqttControl = reactive(useMqttControl());
const { emitter, mobile, mqtt } = getCurrentInstance().appContext.config.globalProperties;
const props = defineProps(['handleNextStep']);
const store = useStore();
const router = useRouter();
const { 
  searchWifiList, 
  connectWifi,
  connectTempWifi, 
  connectTest, 
  readQRCode,
  showRegistView
} = useMobile();
const serialCode = ref('');
const wifiItems = ref(undefined);
const mqttHook = mqttControl.getMqttHook()

onMounted(() => {
  // const canvas = document.getElementById('canvas');
  // QRCode.toCanvas(canvas, 'sertghbye345rytgw4e5tygw45erytgw43e5ytgw3e45tg3qw4tg3we4t5g', function (error) {
  //   if (error) {
  //     console.error(error);
  //     return;
  //   }

  //   canvas.style.width = "100%";
  //   canvas.style.height = "100%";
  //   canvas.style.padding = "2rem";
  //   canvas.style.paddingTop = "1rem";
  // });
});

const onQRCodeRegistClick = async () => {
  try {
    const result = await readQRCode();
    if (result?.length > 0) {
      serialCode.value = result;
    }
  } catch (e) {
    console.log(e);
    emitter.emit('showPopAlert', {
      description: "QR코드 인식에 실패했습니다.\n다시 시도해주세요."
    });
  }
}

const registTempWifi = async () => {
  try {
    const serial = serialCode.value.slice(0, serialCode.value.indexOf("-"));

    if (isAndroid) {
      wifiItems.value = await searchWifiList();
      const foundItem = wifiItems.value.find(item => item.ssid == serialCode.value);
      if (!foundItem) {
        checkTempWifiConnected(serial, () => props.handleNextStep({
          serialCode: serialCode.value
        }), emitter.emit('showPopAlert', {
          description: "잘못된 제품 시리얼 코드 입니다.\n다시 확인해주세요."
        }));
        return;
      }
    }

    const result = await connectTempWifi({
      ssid: serialCode.value,
      password: 'inshow75'
    });
    // const result = await connectTempWifi({
    //   ssid: 'inshowled',
    //   password: 'inshow22'
    // });
    
    console.log("result: ", result)
    if (result) {
      const isVisible = await showRegistView(true);
      console.log("isVisible: ", isVisible)
      if (isVisible) {
        console.log("sleep start");
        emitter.emit('showLoading');

        if (isIOS) {
          await mqtt.reconnect();
        }
        
        await sleep(10);
        emitter.emit('hideLoading');
        console.log("sleep end")

        let intervalCount = 0;
        emitter.emit('showLoading');
        const intervalId = setInterval(async () => {
          try {
            console.log("intervalCount", intervalCount)
            if (intervalCount >= 10) {
              emitter.emit('showPopAlert', {
                description: '컨트롤러로부터 응답을 받을 수 없습니다.'
              });
              clearInterval(intervalId);
              emitter.emit('hideLoading');
              return;
            }

            // const isAuthConnect = await mqttControl.connectAuth();
            // console.log("---------------------------------", isAuthConnect)
            // if (isAuthConnect) {
              checkTempWifiConnected(serial, () => {
                clearInterval(intervalId);
                emitter.emit('hideLoading');
                props.handleNextStep({ serialCode: serialCode.value })
              }, () => {
                console.log("checkTempWifiConnected failed...", intervalCount)
              });
            // }

            // }), () => emitter.emit('showPopAlert', {
            //   description: '설정에 실패했습니다.'
            // }));

            console.log("intervalCount", intervalCount)
            intervalCount++;
          } catch(e) {
            console.log("connect auth mqtt: failed");
            intervalCount++;
          }
        }, 6000);
      } else {
        emitter.emit('showPopAlert', {
          description: 'wifi 등록을 취소하였습니다.'
        });
      }
    }
  } catch (e) {
    emitter.emit('showPopAlert', {
      description: e
    });
  }
}

const requestDeviceInfo = async (payload) => {
  try {
    const result = await Axios.get(DeviceApi.DEVICE_INFO_BY_DATA, payload);
    if (result.resultCode === '0000' && result.resultData) {
      emitter.emit('showPopAlert', {
        description: "이미 등록된 장치입니다."
      });
      return;
    }    

    checkTempWifiConnected(payload.controllerId, () => props.handleNextStep({
      serialCode: serialCode.value
    }), registTempWifi);
  } catch (e) {
    console.log(e);
    // emitter.emit('showPopAlert', {
    //   description: 'WIFI 스캔 중 오류가 발생하였습니다.',
    // });
    checkTempWifiConnected(payload.controllerId, () => props.handleNextStep({
      serialCode: serialCode.value
    }), registTempWifi);
  }
}

const onRegistClick = async () => {
  if (serialCode.value.length === 0) {
    emitter.emit('showPopAlert', {
      description: "QR코드를 인식하거나\n수동으로 제품 시리얼 코드를 입력해주세요."
    });
    return;
  }

  const serial = serialCode.value.slice(0, serialCode.value.indexOf("-"));
  requestDeviceInfo({
    controllerId: serial,
    name: serialCode.value
  });
}

const checkTempWifiConnected = async (serial, handleSuccess, handleFailure) => {
  emitter.emit('showLoading');

  const testTopic = `/inshow/${serial}/TEMPTARGETSET`;
  let intervalCount = 0;

  mqttHook.publish("cmnd" + testTopic, "18", 0);
  const intervalId = setInterval(async () => {
    if (intervalCount >= 5) {
      clearInterval(intervalId);
      console.log("failed... : stat" + testTopic)

      await mqttHook.unRegisterEvent("stat" + testTopic);
      await mqttHook.unSubscribe("stat" + testTopic, undefined);      

      emitter.emit('hideLoading');

      if (handleFailure) {
        handleFailure();
      }
      return;
    }

    mqttHook.publish("cmnd" + testTopic, "18", 0);
    console.log("cmnd" + testTopic, "18")
    intervalCount++;
  }, 1000);

  mqttHook.subscribe(["stat" + testTopic], 0, undefined, async () => {
    await mqttHook.registerEvent("stat" + testTopic, async (topic,message) =>{
      console.log("success!!!!!!!!!!!", topic, message);
      
      if (topic.includes(testTopic)) {
        clearInterval(intervalId);

        await mqttHook.unRegisterEvent(topic);
        await mqttHook.unSubscribe(topic, undefined);  

        emitter.emit('hideLoading');

        if (handleSuccess) {
          handleSuccess();
        }
      }
    });
  });
}

const test = () => {
  // const serial = serialCode.value.slice(0, serialCode.value.indexOf("-"));
  // const topic = `/inshow/${serial}/TEMPTARGETSET`;
  // console.log("cmnd" + topic, "18")
  // mqttHook.publish("cmnd" + topic, "18", 0);

  // mqttHook.subscribe(["stat" + topic], 0, undefined, async () => {
  //   await mqttHook.registerEvent("stat" + topic, async (topic,message) =>{
  //     console.log(topic, message);
  //     await mqttHook.unRegisterEvent(topic);
  //     await mqttHook.unSubscribe(topic, undefined);  
  //   });
  // });
}

const test2 = async () => {
  // const serial = serialCode.value.slice(0, serialCode.value.indexOf("-"));
  // const topic = `/inshow/${serial}/TEMPTARGETSET`;
  // console.log("cmnd" + topic, "18")
  // mqttHook.publish("cmnd" + topic, "18", 0);
}

const sleep = (sec) => {
  return new Promise(resolve => setTimeout(resolve, sec * 1000));
}
</script>