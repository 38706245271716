<template>
  <PopDialog2
    title="방 정보 수정"
    :visible="props.visible"
    :okCallback="onOkClick"
    :cancelCallback="onCancelClick"
  >
    <div class="md-layout">
      <div class="md-layout-item md-size-50 mx-auto text-center mb-4">
        <!-- <h2 class="title mt-4">{{props.roomName}} 보일러</h2> -->
        <h2 class="title mt-4">보일러</h2>
        <button
          type="button"
          class="btn btn-light mb-4"
          :style="`width: 6rem; height: 6rem; border-radius: 3rem; background-color: ${deviceState(2) ? '#ffe082' : 'inherits'}`"
          @click="props.handlePowerClick"
        >
          <span style="font-size: 1.0rem">{{ deviceState(2) ? currentTemp : "외출중" }}</span>
        </button>

        <div style="margin-right: 20px;">
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <!-- <Slider
              :name="
                device?.item?.temperature
                ? `온도 ${device.item.temperature}&#176;c`
                : '온도'
              "
              id="temperature"
              :default="targetTemp"
              :nameWidth="3"
              :min="9"
              :max="36"
              v-on:onSliderChanged="onTemperatureChanged"
            /> -->
            <Slider
              :name="`온도 ${targetTemp}&#176;c`"
              id="temperature"
              :default="targetTemp"
              :nameWidth="3"
              :min="9"
              :max="36"
              :step="0.5"
              :isFloat="true"
              :disabled="deviceState(2) ? false : true"
              v-on:onSliderChanged="onTemperatureChanged"
            />
          </ListGroupItem>
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <div class="container" style="padding-left: 0px; padding-right: 0px;">
              <div class="row">
                <div
                  :class="`col-3`"
                  :style="`margin-top: 8px`"
                >
                  <label :for="props.id" class="form-label w-normal">
                    패턴
                  </label>
                </div>
                <div class="col">
                  <div class="btn-group btn-group-toggle" data-toggle="buttons" style="width: 100%;">
                    <!-- <div class="form-check"> -->
                      <label :class="`btn ${currentPattern == 1 ? 'btn-primary' : 'btn-outline-primary'} ${currentPattern == 1 ? 'selected' : 'no-selected'}`" 
                        style="border-radius: 10px 0px 0px 10px;">
                        <input class="form-check-input" type="checkbox" id="p1" value="1" :checked="currentPattern == 1" @click="onSelectPatternClick" hidden>
                        <label class="form-check-label" for="p1">
                          P1
                        </label>
                      </label>

                      <label :class="`btn ${currentPattern == 2 ? 'btn-primary' : 'btn-outline-primary'} ${currentPattern == 2 ? 'selected' : 'no-selected'}`" 
                        style="border-radius: 0px 0px 0px 0px;">
                        <input class="form-check-input" type="checkbox" id="p2" value="2" :checked="currentPattern == 2" @click="onSelectPatternClick" hidden>
                        <label class="form-check-label" for="p2">
                          P2
                        </label>
                      </label>

                      <label :class="`btn ${currentPattern == 3 ? 'btn-primary' : 'btn-outline-primary'} ${currentPattern == 3 ? 'selected' : 'no-selected'}`" 
                        style="border-radius: 0px 0px 0px 0px;">
                        <input class="form-check-input" type="checkbox" id="p3" value="3" :checked="currentPattern == 3" @click="onSelectPatternClick" hidden>
                        <label class="form-check-label" for="p3">
                          P3
                        </label>
                      </label>

                      <label :class="`btn ${currentPattern == 4 ? 'btn-primary' : 'btn-outline-primary'} ${currentPattern == 4 ? 'selected' : 'no-selected'}`" 
                        style="border-radius: 0px 0px 0px 0px;">
                        <input class="form-check-input" type="checkbox" id="p4" value="4" :checked="currentPattern == 4" @click="onSelectPatternClick" hidden>
                        <label class="form-check-label" for="p4">
                          P4
                        </label>
                      </label>

                      <label :class="`btn ${currentPattern == 5 ? 'btn-primary' : 'btn-outline-primary'} ${currentPattern == 5 ? 'selected' : 'no-selected'}`" 
                        style="border-radius: 0px 10px 10px 0px;">
                        <input class="form-check-input" type="checkbox" id="p5" value="5" :checked="currentPattern == 5" @click="onSelectPatternClick" hidden>
                        <label class="form-check-label" for="p5">
                          P5
                        </label>
                      </label>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem 
            style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
          >
            <div class="container" style="padding-left: 0px; padding-right: 0px;">
              <div class="row">
                <div
                  :class="`col-3`"
                  :style="`margin-top: 8px`"
                >
                </div>
                <div class="col">
                  <div class="flex-fill">
                    <div
                      class="fw-400 text-muted text-end me-1"
                      style="font-size: 0.8rem"
                      @click="onSettingTempPatternsClick"
                    >
                      패턴 정보&설정&nbsp;<i class="fa fa-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ListGroupItem>
        </div>
      </div>
    </div>
  </PopDialog2>
        
  <BoilerItemPatternModal
    v-if="boilerItemPatternModal.visible"
    :visible="boilerItemPatternModal.visible"
    :cancelCallback="boilerItemPatternModal.hide"
    :roomName="props.roomName"
    :roomId="props.roomId"
  />
</template>

<script setup>
import { ref, defineProps, computed, getCurrentInstance, reactive } from 'vue';
import { useStore } from 'vuex';
import { DeviceType, PowerState, MQTTTempCommand } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';
import useModal from '@/common/hooks/useModal';
import PopDialog2 from '@/common/components/popup/PopDialog2.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import BoilerItemPatternModal from './BoilerItemPatternModal.vue';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const boilerItemPatternModal = reactive(useModal());
const store = useStore();
const props = defineProps([
  'visible',
  'okCallback',
  'cancelCallback',
  'roomName',
  'roomId',
  'handlePowerClick'
]);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});
const boilerDevices = computed(() => {
  return devices.value.filter(
    (d) => d.type === 2,
  );
});

const currentPattern = ref(boilerDevices.value.length > 0 ? boilerDevices.value[0].item.pattern : "0");

const currentTemp = computed(() => {
  if (boilerDevices.value.length === 0) {
    return 20;
  }

  let currentTemp = undefined;
  boilerDevices.value.forEach((device) => {
    if (currentTemp === undefined) {
      currentTemp = device.item?.currentTemp;
    }
  });
  return currentTemp;
});
const targetTemp = computed(() => {
  if (boilerDevices.value.length === 0) {
    return 18;
  }

  let targetTemp = 0;
  boilerDevices.value.forEach((device) => {
    if (device.item?.targetTemp > targetTemp) {
      targetTemp = device.item?.targetTemp;
    }
  });
  return targetTemp;
});
const deviceState = (type) => {
  return devices.value
    .filter((d) => d.type === type)
    .some((d) => d.item.onoff === PowerState.On);
};

const onCancelClick = () => {
  if (props.cancelCallback) {
    props.cancelCallback();
  }
};

const onTemperatureChanged = (e) => {
  boilerDevices.value.forEach((device) => {
    mqttControl.onPublishTemp(device.controllerId, MQTTTempCommand.TempTargetSet, e);
  });
};

const onSelectPatternClick = (e) => {
  if (e.target.value) {
    e.preventDefault();

    if (currentPattern.value == e.target.value) {
      currentPattern.value = 0;
    } else {
      currentPattern.value = e.target.value;
    }

    boilerDevices.value.forEach((device) => {
      mqttControl.onPublishTemp(device.controllerId, MQTTTempCommand.PatternModeSet, currentPattern.value);
    });
  }
}

const onSettingTempPatternsClick = () => {
  boilerItemPatternModal.show();
}
</script>

<style scoped>
.selected:hover {
  color: #ffffff;
  background-color: #1f6bff;
}
.no-selected:hover {
  color: #1f6bff;
  background-color: #ffffff;
}
</style>