<template>
  <div>
    <div class="row">
      <RoomItem
        v-for="(item, index) in groups"
        :key="item._id"
        :index="index"
        :roomName="item.name"
        :roomId="item._id"
        :power="getPower(item)"
        v-on:onPowerClicked="onRoomPowerClicked"
      />
    </div>
    <ZoneModal
      v-if="zoneModal.visible"
      :visible="zoneModal.visible"
      :okCallback="onUpdateZoneName"
      :cancelCallback="zoneModal.hide"
      :zoneName="zone?.name"
    />

    <NoticeItemModal
      v-if="noticeItemModal.visible"
      :visible="noticeItemModal.visible"
      :cancelCallback="onNoticeCancelClick"
      :data="currentNotice"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  reactive,
  ref, watch
} from 'vue';
import moment from 'moment'
import { useRouter } from 'vue-router';
import Axios, { ZoneApi, SmarthomeApi } from '@/api';
import RoomItem from './components/RoomItem.vue';
import ZoneModal from './components/ZoneModal.vue';
import useModal from '@/common/hooks/useModal';
import { ButtonConfig, PowerState, DeviceType, MQTTTempCommand, MQTTCurtainCommand } from '@/common/constants';
import OnOffButton from '@/common/components/widgets/OnOffButton.vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import CardHeader from '@/common/template/components/bootstrap/CardHeader.vue';
import CardFooter from '@/common/template/components/bootstrap/CardFooter.vue';
import CardGroup from '@/common/template/components/bootstrap/CardGroup.vue';
import CardImgOverlay from '@/common/template/components/bootstrap/CardImgOverlay.vue';
import CardExpandToggler from '@/common/template/components/bootstrap/CardExpandToggler.vue';
import NoticeItemModal from '../notices/components/NoticeItemModal.vue';
import useMqttControl from '@/common/hooks/useMqttControl';
import useMobile from '@/common/hooks/useMobile';
import { isMobile } from 'mobile-device-detect';

const mqttControl = reactive(useMqttControl());
const router = useRouter();
const store = useStore();
const zoneModal = reactive(useModal());
const noticeItemModal = reactive(useModal());
const showNotices = ref([]);
const currentNotice = ref();
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const { getLocalStorage, setLocalStorage } = useMobile();

const notices = computed(() => store.getters['home/getNotices']);

const zones = computed(() => {
  return store.getters['home/getZones'];
});
const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});

const groups = computed(() => {
  return zone.value?.groups || [];
});

const showNoticesPopup = async () => {
  const filteredNotices = notices.value.filter((notice) => {
    const todayDate = moment().format("YYYYMMDD");
    const startDate = moment(notice.startDate).format("YYYYMMDD");
    const endDate = moment(notice.endDate).format("YYYYMMDD");
    return endDate > todayDate || todayDate < startDate;
  });

  if (isMobile) {
    try {
      const promises = filteredNotices.map(notice => getLocalStorage(`notice${notice._id}`));
      const result = await Promise.all(promises);
      showNotices.value = filteredNotices.filter((notice, index) => !result[index]);
    } catch (e) {
      console.log(e);
    }
  }

  if (showNotices.value.length > 0) {
    currentNotice.value = showNotices.value[0];
  }
}

const setEditModeOff = () => {
  const editZones = zones.value.filter(zone => zone.isEditMode);
  if (editZones.length > 0) {
    console.log("세팅모드의 영역이 있습니다. 세팅모드를 종료합니다.");
  }

  editZones.forEach(async (zone) => {
    const payload = { isEditMode: false };
    const result = await Axios.patch(
      ZoneApi.UPDATE_ZONE.replace(':id', zone._id),
      payload,
    );
    store.commit('home/updateZone', {
      id: zone._id,
      data: payload,
    });

    if (result.resultCode === '0000') {
      mqttControl.onPublishZone(zone._id, payload);
    };
  })
}

onMounted(() => {
  showNoticesPopup();
  setEditModeOff();
});

watch(currentNotice, () => {
  if (currentNotice.value) {
    noticeItemModal.show();
  }
});

watch(notices, () => {
  if (!currentNotice.value) {
    showNoticesPopup();
  }
})

const onNoticeCancelClick = () => {
  noticeItemModal.hide();
  setTimeout(() => {
    const index = showNotices.value.indexOf(currentNotice.value);
    currentNotice.value = showNotices.value[index + 1];
  }, 10);
}

const getPower = (group) => {
  return group.devices.some((d) => d.item?.onoff === 1);
};

const onPowerOnClick = () => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }

  zone.value.controllers.forEach((item) => {
    mqttControl.onPublishFirmware(item._id, {
      serial: item._id,
      cmd: 'ao',
      type: 1,
      onoff: 1,
    });
  });
};

const onPowerOffClick = () => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }
  zone.value.controllers.forEach((item) => {
    mqttControl.onPublishFirmware(item._id, {
      serial: item._id,
      cmd: 'ao',
      type: 1,
      onoff: 0,
    });
  });
};

const onRoomPowerClicked = (payload) => {
  console.log(payload)
  let { id, type, onoff, close, groupId } = payload;
  let devices;
  if (type) {
    devices = groups.value
      .find((g) => g._id === id)
      .devices.filter((d) => d.type === type);
  } else {
    devices = groups.value.find((g) => g._id === id).devices;
  }

  if (type == DeviceType.Lamp) {
    onPublishLightPowerChanged(devices, onoff, groupId);
  } else if (type == DeviceType.Boiler) {
    onPublishBoilerPowerChanged(devices, onoff, groupId);
  } else if (type == DeviceType.Curtain) {
    onPublishCurtainPowerChanged(devices, close, groupId);
  }
};

const onPublishLightPowerChanged = (devices, onoff, groupId) => {
  emitter.emit('showLoading');

  devices.forEach(async (device) => {
    if (device.isVirtual && onoff == 0) {
      const params = { onoff: onoff, disableRollback: true };
      await requestVirtualDevicePower(params, device._id, groupId);
    } else {
      console.log(device);
      const data = {
        ports: device.item.ports?.map((p) => p),
        onoff: onoff,
        bright: device.item.bright,
        color: device.item.color,
      };
      mqttControl.onPublish(device.controllerId, device._id, {
        serial: device.controllerId,
        cmd: 'c',
        type: device.type,
        data: data,
      });
    }

    setTimeout(() => emitter.emit('hideLoading'), ButtonConfig.delay);
  });
};

const onPublishCurtainPowerChanged = (devices, close, groupId) => {
  console.log(devices, close, groupId);
  return;
  emitter.emit('showLoading');

  devices.forEach(async (device) => {
    if (device.isVirtual && onoff == 0) {
      const params = { onoff: onoff, disableRollback: true };
      await requestVirtualDevicePower(params, device._id, groupId);
    } else {
      if (close) {
        mqttControl.onPublishTemp(device.controllerId, MQTTCurtainCommand.IsClose, 1);
      } else {
        mqttControl.onPublishTemp(device.controllerId, MQTTCurtainCommand.IsOpen, 1);
      }
    }

    setTimeout(() => emitter.emit('hideLoading'), ButtonConfig.delay);
  });
};

const onPublishBoilerPowerChanged = (devices, onoff, groupId) => {
  console.log(devices, onoff, groupId)
  emitter.emit('showLoading');

  devices.forEach(async (device) => {
    if (device.isVirtual && onoff == 0) {
      const params = { onoff: onoff, disableRollback: true };
      await requestVirtualDevicePower(params, device._id, groupId);
    } else {
      mqttControl.onPublishTemp(device.controllerId, MQTTTempCommand.AwayModeSet, onoff);
    }

    setTimeout(() => emitter.emit('hideLoading'), ButtonConfig.delay);
  });
};

const onEditZoneClick = () => {
  zoneModal.show();
};

const onUpdateZoneName = (value) => {
  requestUpdateZone({
    name: value,
  });
  zoneModal.hide();
};

const requestUpdateZone = async (params) => {
  try {
    const result = await Axios.patch(
      ZoneApi.UPDATE_ZONE.replace(':id', zone?.value._id),
      params,
    );
    if (result.resultCode === '0000') {
      store.commit('home/updateZone', {
        id: zone?.value._id,
        data: params,
      });
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '집 정보 수정에 실패했습니다.',
    });
  }
};

const requestVirtualDevicePower = async (params, id, groupId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.patch(SmarthomeApi.VIRTUAL_DEVICE_POWER.replace(":id", id), params);
      if (result.resultCode === '0000') {
        console.log(result, groupId)
        if (result.resultData.onItem && result.resultData.offItem) {
          store.commit('home/updateDevice', {
            groupId: groupId,
            id: result.resultData.offItem._id,
            data: {
              item: { onoff: 0 }
            },
          });
          store.commit('home/updateDevice', {
            groupId: groupId,
            id: result.resultData.onItem._id,
            data: {
              item: result.resultData.onItem.item
            },
          });

        } else {
          store.commit('home/updateDevice', {
            groupId: groupId,
            id: id,
            data: {
              item: result.resultData.item
            },
          });
        }
        resolve(true);
        return;
      }

      reject(false);
      // emitter.emit('showPopAlert', {
      //   description: result.resultMessage,
      // });
    } catch (e) {
      reject(false);
      console.log(e);
      // emitter.emit('showPopAlert', {
      //   description: '가상버튼 ON/OFF에 실패했습니다.',
      // });
    }
  });
};

const onSettingClick = () => {
  router.push('/settings/zones');
};
</script>
