<template>    
  <div v-if="!device?.isVirtual" class="list-group" ref="lightItem" style="border-radius: 1.2rem; margin-top: 1px;" @click="onLightDevicePowerClicked" v-touch:press="onTouchDown" v-touch:drag="onTouchDrag" v-touch:release="onTouchRelease" v-touch:hold="onTouchHold">
    <div class="list-group-item d-flex align-items-center" :style="`height: 4rem; padding: 2px 0px; background: ${getPower ? `linear-gradient(to right, ${buttonColor} ${percent}%, ${buttonAnotherColor} ${percent}%)` : 'inherits'};`">
      <i class="fa-solid fa-lightbulb ms-3 me-2" />
      <div class="flex-fill" style="font-size: 0.8rem">
        {{ device.name + `${device.isVirtual ? "(가상버튼)" : ""}` }}
        <br>
        {{ getPower ? currentBrightText : "" }}
      </div>
      <div class="mx-1 h-100" @click.stop="onSettingClicked">
        <i :class="`fa-solid fa-gear me-3 mt-4`" style="font-size: 0.8rem" />
      </div>
      <!-- <div class="mx-1" @click.stop="onLightDeviceInfoClicked">
        <i class="fa fa-ellipsis" />
      </div> -->
    </div>
  </div>

  <div v-else class="list-group" ref="lightItem" style="border-radius: 1.2rem; margin-top: 1px;" @click="onLightDevicePowerClicked" v-touch:hold="onTouchHold">
    <div class="list-group-item d-flex align-items-center" :style="`height: 4rem; padding: 2px 0px; background: ${getPower ? `linear-gradient(to right, #ffe082 100%, #fff8e1 100%)` : 'inherits'};`">
      <i class="fa-solid fa-lightbulb ms-3 me-2" />
      <div class="flex-fill" style="font-size: 0.8rem">
        {{ device.name }} (가상버튼)
      </div>
      <div class="mx-1 h-100" @click.stop="onSettingClicked">
        <i :class="`fa-solid fa-gear me-3 mt-4`" style="font-size: 0.8rem" />
      </div>
      <!-- <div class="mx-1" @click.stop="onLightDeviceInfoClicked">
        <i class="fa fa-ellipsis" />
      </div> -->
    </div>
  </div>
        
  <LightDeviceItemModal
    v-if="lightDeviceItemModal.visible"
    :visible="lightDeviceItemModal.visible"
    :cancelCallback="lightDeviceItemModal.hide"
    :roomName="props.roomName"
    :roomId="props.roomId"
    :deviceId="props.deviceId"
    :handlePowerClick="onLightDevicePowerClicked"
  />
</template>

<script setup>
import Axios, { DeviceApi, VirtualDeviceApi, SmarthomeApi } from '@/api';
import { reactive, defineProps, getCurrentInstance, computed, onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { DeviceType, PowerState, ButtonConfig, lightButtonColors, lightButtonAnotherColors } from '@/common/constants';
import LightDeviceItemModal from './LightDeviceItemModal.vue';
import useModal from '@/common/hooks/useModal';
import useMqttControl from '@/common/hooks/useMqttControl';
import useDeviceTouch from '@/common/hooks/useDeviceTouch';

const mqttControl = reactive(useMqttControl());
const props = defineProps(['roomId', 'roomName', 'deviceId']);
const store = useStore();
const lightDeviceItemModal = reactive(useModal());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const lightItem = ref(null);
const percent = ref(0.0);
const currentBright = ref(0);
const { 
  handlePower, 
  handleTouchDown, 
  handleTouchDrag, 
  handleTouchRelease, 
  handleTouchHold 
} = useDeviceTouch(props.roomId);

const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});
const group = computed(() => {
  return zone.value.groups.find((g) => g._id === props.roomId);
});
const devices = computed(() => {
  return group.value ? group.value.devices : undefined;
});
const virtualDevices = computed(() => {
  return devices.value?.filter(item => item.isVirtual && item._id != props.deviceId);
});
const device = computed(() => {
  return devices.value.find(
    (d) => d._id === props.deviceId,
  );
});

const getPower = computed(() => {
  return device.value.item.onoff === PowerState.On;
});
const getBright = computed(() => {
  return device.value.item.bright;
});
const getColor = computed(() => {
  return device.value.item?.color;
});

const buttonColor = computed(() => {
  const index = device.value.item?.color / 2;
  return device.value.item?.color !== undefined && lightButtonColors.length > index ? lightButtonColors[index] : "#ffe082";
});
const buttonAnotherColor = computed(() => {
  const index = device.value.item?.color / 2;
  return device.value.item?.color !== undefined && lightButtonAnotherColors.length > index ? lightButtonAnotherColors[index] : "#fff8e1";
});

const currentBrightText = computed(() => {
  if (device?.value?.isVirtual) {
    return "";
  }
  return `${parseInt(getBright.value)}%`;
})

watch(getBright, () => {
  currentBright.value = getBright.value;
  percent.value = (getBright.value - 10) / 90 * 100;
})

onMounted(() => {
  currentBright.value = device.value.item.bright;
  percent.value = (currentBright.value - 10) / 90 * 100;
});

const requestChangeVirtualDevicePower = async (params, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.patch(SmarthomeApi.CHANGE_VIRTUAL_DEVICE_POWER.replace(":id", id), params);
      const beforeDevice = virtualDevices.value.find(item => item.item.onoff > 0);

      if (result.resultCode === '0000') {
        if (beforeDevice) {
          store.commit('home/updateDevice', {
            groupId: props.roomId,
            id: beforeDevice._id,
            data: {
              item: { onoff: 0 }
            },
          });
        }
        store.commit('home/updateDevice', {
          groupId: props.roomId,
          id: id,
          data: {
            item: result.resultData.item
          },
        });
        resolve(true);
        return;
      }

      reject(false);
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    } catch (e) {
      reject(false);
      console.log(e);
      emitter.emit('showPopAlert', {
        description: '가상버튼 ON/OFF에 실패했습니다.',
      });
    }
  });
};

const requestVirtualDevicePower = async (params, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.patch(SmarthomeApi.VIRTUAL_DEVICE_POWER.replace(":id", id), params);
      if (result.resultCode === '0000') {
        console.log(result);
        
        if (result.resultData.onItem && result.resultData.offItem) {
          store.commit('home/updateDevice', {
            groupId: props.roomId,
            id: result.resultData.offItem._id,
            data: {
              item: { onoff: 0 }
            },
          });
          store.commit('home/updateDevice', {
            groupId: props.roomId,
            id: result.resultData.onItem._id,
            data: {
              item: result.resultData.onItem.item
            },
          });

        } else {
          store.commit('home/updateDevice', {
            groupId: props.roomId,
            id: id,
            data: {
              item: result.resultData.item
            },
          });
        }
        resolve(true);
        return;
      }

      reject(false);
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    } catch (e) {
      reject(false);
      console.log(e);
      emitter.emit('showPopAlert', {
        description: '가상버튼 ON/OFF에 실패했습니다.',
      });
    }
  });
};

const onLightDevicePowerClicked = (e) => {
  handlePower(async () => {
    try {
      if (zone.value.isEditMode) {
        emitter.emit('showPopAlert', {
          description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
        });
        return;
      }

      emitter.emit('showLoading');

      if (device.value?.isVirtual) {
        const params = { onoff: getPower.value ? PowerState.Off : PowerState.On };
        const onVirtualDevice = virtualDevices.value.find(item => item.item.onoff > 0);
        if (onVirtualDevice) {
          params["onVirtualDeviceId"] = onVirtualDevice._id;
          // await requestChangeVirtualDevicePower(params, device.value._id);
          const result = await requestVirtualDevicePower(params, device.value._id);
        } else {
          await requestVirtualDevicePower(params, device.value._id);
        }
      } else {
        const data = {
          ports: device.value.item.ports.map((p) => p),
          onoff: getPower.value ? PowerState.Off : PowerState.On,
          bright: device.value.item.bright,
          color: device.value.item.color,
        };

        mqttControl.onPublish(device.value.controllerId, device.value._id, {
          serial: device.value.controllerId,
          cmd: 'c',
          type: device.value.type,
          data: data,
        });
      }
      setTimeout(() => emitter.emit('hideLoading'), ButtonConfig.delay);
    } catch (e) {
      emitter.emit('hideLoading');
    }
  });
};

const onLightDeviceInfoClicked = (e) => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }
  lightDeviceItemModal.show();
};

const onSettingClicked = (e) => {
  onLightDeviceInfoClicked();
}

const brightChange = () => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }

  mqttControl.onPublish(device.value.controllerId, device.value._id, {
    serial: device.value.controllerId,
    cmd: 'c',
    type: 1,
    data: {
      ports: device.value.item.ports.map((p) => p),
      onoff: device.value.item.onoff,
      bright: parseInt(currentBright.value),
      color: device.value.item.color,
    },
  });
};

const onTouchDown = (e) => {
  if (device.value.isVirtual) {
    return;
  }

  if (getPower.value) {
    handleTouchDown(e);
  }
}

const onTouchDrag = (e) => {
  if (device.value.isVirtual) {
    return;
  }
  
  handleTouchDrag(e, (screenX, startX) => {
    percent.value = percent.value + (screenX - startX) / lightItem.value.clientWidth * 100.0;
    percent.value = percent.value <= 0 ? 0 : percent.value;
    percent.value = percent.value >= 100 ? 100 : percent.value;
    currentBright.value = parseInt((9 * percent.value * 0.01) + 1) * 10;
  });
}

const onTouchRelease = (e) => {
  if (device.value.isVirtual) {
    return;
  }
  
  handleTouchRelease(e, brightChange);
}

const onTouchHold = (e) => {
  handleTouchHold(e, onLightDeviceInfoClicked);
}
</script>
