import { DeviceType, SettingMode } from '@/common/constants';
import Axios, { ZoneApi, FirmwareApi, ProductApi, NoticeApi } from '@/api';
import { MQTTCommand, MQTTTempCommand, MQTTCurtainCommand } from '../common/constants';

export default {
  namespaced: true,
  state: {
    current: {
      zoneId: 0,
      groupId: 0,
      deviceId: 0,
    },
    settingModeDevices: [],
    zones: [],
    firmwares: [],
    products: [],
    notices: [],
    checkControllers: {},
    isWebViewReady: false
  },
  getters: {
    getZones: (state) => {
      return state.zones;
      // return state.zones.filter((z) => {
      //   return z.isEditMode === false;
      // });
    },
    getCurrent: (state) => {
      return state.current;
    },
    getCurrentZone: (state) => {
      return state.zones.find((z) => z._id === state.current.zoneId);
    },
    getCurrentGroup: (state) => {
      return state.zones
        ?.find((z) => z._id === state.current.zoneId)
        ?.groups.find((g) => g._id === state.current.groupId);
    },
    getCurrentDevice: (state) => {
      return state.zones
        ?.find((z) => z._id === state.current.zoneId)
        ?.groups.find((g) => g._id === state.current.groupId)
        ?.devices.find((d) => d._id === state.current.deviceId);
    },

    getSettingModeDevices: (state) => {
      return state.settingModeDevices;
    },
    getFirmwares: (state) => {
      return state.firmwares;
    },
    getProducts: (state) => {
      return state.products;
    },
    getNotices: (state) => {
      return state.notices;
    },
    getControllers: (state) => {
      if (state.zones?.length === 0) {
        return [];
      }

      const filteredControllers = [];
      state.zones.forEach((zone) => {
        const controllers = zone.controllers
          .map((controller) => {
            const targetProduct = state.products.find(
              (product) => product.model === controller.model,
            );

            return {
              ...controller,
              lastVersion: targetProduct?.version || undefined
            };
          })
          
          filteredControllers.push(...controllers);
      });

      return [...new Set(filteredControllers)];
    },
    getLowVersionControllers: (state) => {
      if (state.zones?.length === 0 || state.products?.length === 0) {
        return [];
      }

      const filteredControllers = [];
      state.zones.forEach((zone) => {
        const controllers = zone.controllers
          .map((controller) => {
            const targetProduct = state.products.find(
              (product) => product.model === controller.model,
            );

            return {
              ...controller,
              lastVersion: targetProduct?.version || undefined
            };
          })
          .filter((controller) => controller.lastVersion && controller.lastVersion > controller.version);
          
          filteredControllers.push(...controllers);
      });

      const resultControllers = filteredControllers.map((controller) => {
        const firmware = state.firmwares.find((firmware) => firmware.model == controller.model && firmware.version == controller.lastVersion)
        return {
          ...controller,
          downloadUrl: firmware?.downloadUrl || ""
        };
      });

      return [...new Set(resultControllers)];
    },
    getFirmwareUpdatingControllers: (state) => {
      const updateControllers = [];
      state.zones.forEach((z) => {          
        updateControllers.push(...z.controllers.filter((ctrl) => ctrl.firmwareState === "fs") || []);
      })

      return updateControllers

      // return (
      //   state.zones
      //     .find((zone) =>
      //       zone.controllers.some((ctrl) => ctrl.firmwareState === 'fs'),
      //     )
      //     ?.controllers.filter((ctrl) => ctrl.firmwareState === 'fs') || []
        
      // );
    },
    getFirmwareUpdatedControllers: (state) => {
      const updateControllers = [];
      state.zones.forEach((z) => {          
        updateControllers.push(...z.controllers.filter((ctrl) => ctrl.firmwareState === "fe") || []);
      });

      return updateControllers;

      // return (
      //   state.zones
      //     .find((zone) =>
      //       zone.controllers.some((ctrl) => ctrl.firmwareState === 'fe'),
      //     )
      //     ?.controllers.filter((ctrl) => ctrl.firmwareState === 'fe') || []        
      // );
    },
    availableFirmwareUpdate : (state) => {
      return state.zones.filter((zone) =>
        zone.controllers.some((controller) => {
          let firmware = state.firmwares.find(
            (firmware) => controller.model === firmware.model
          );
          return controller.version !== firmware.version;
        })
      );
    },
    isWebViewReady: (state) => {
      return state.isWebViewReady;
    },
    getCheckControllers: (state) => {
      return state.checkControllers;
    },
  },
  mutations: {
    setCurrentZone: (state, id) => {
      console.log('store/home. => setCurrentZone:', id);
      state.current.zoneId = id;
    },
    setCurrentGroup: (state, id) => {
      state.current.groupId = id;
    },
    setCurrentDevice: (state, id) => {
      state.current.deviceId = id;
    },
    setCurrent: (state, payload) => {
      state.current = payload;
    },
    setZones: (state, payload) => {
      state.zones = payload.map(zone => ({
        ...zone,
        groups: zone.groups.sort((a, b) => {
          if (a.sort && !b.sort) return -1;
          if (!a.sort && b.sort) return 1;
          if (!a.sort && !b.sort) return 0;
          if (a.sort > b.sort) return 1;
          if (a.sort < b.sort) return -1;
          return 0;
        })
      }));
    },
    setDeviceState: (state, payload) => {
      console.log('store/home => setDeviceState:',payload)

      let device = state.zones
        .find((z) =>
          z.groups.some((g) =>
            g.devices.some((d) => d._id === payload.deviceId),
          ),
        )
        .groups.find((g) => g.devices.some((d) => d._id === payload.deviceId))
        .devices.find((d) => d._id === payload.deviceId);

      // console.log('store/home => setDeviceState => device : ',device)

      // 전원은 모든 디바이스 동일
      // device.item.onoff = payload.state.onoff;

      switch (payload.type) {
        case 1: //조명
          device.item.onoff = payload.state.onoff;
          if (payload.state.bright == 0 && payload.state.color == 0) {
            console.log("payload.state.bright:", payload.state.bright, ", payload.state.color", payload.state.color)
          } else {
            if (payload.state.bright) device.item.bright = payload.state.bright;
            device.item.color = payload.state.color;
          }
          break;
        case 2: //보일러
          device.item = {
            ...device.item,
            ...payload.state,
            customPattern: {
              ...device.item.customPattern,
              ...(payload.state.customPattern || {}) 
            }
          };
          break;
        case 3: //커튼
          console.log({
            ...device.item,
            ...payload.state
          })
          device.item = {
            ...device.item,
            ...payload.state
          };
          break;
        case 4: //뢈춘디
          device.item.onoff = payload.state.onoff;
          break;
      }
    },
    addZone: (state, payload) => {
      console.log(payload);
      state.zones = state.zones.concat(payload);
      if (state.zones.length === 1) {
        state.current.zoneId = payload._id;
      }
    },
    addGroup: (state, payload) => {
      const targetZone = state.zones.find((z) => z._id == payload?.zoneId);
      if (!targetZone) {
        return;
      }

      state.zones = state.zones.map((zone) =>
        zone._id == payload.zoneId
          ? {
              ...zone,
              groups: zone.groups.concat(payload.data).sort((a, b) => {
                if (a.sort && !b.sort) return -1;
                if (!a.sort && b.sort) return 1;
                if (!a.sort && !b.sort) return 0;
                if (a.sort > b.sort) return 1;
                if (a.sort < b.sort) return -1;
                return 0;
              }),
            }
          : zone,
      );
    },
    addDevice: (state, payload) => {
      const targetZone = state.zones.find((z) => z._id == payload?.zoneId);
      const targetGroup = targetZone?.groups?.find(
        (group) => group._id == payload?.groupId,
      );
      if (!targetZone || !targetGroup) {
        return;
      }

      console.log(payload);

      let group = state.zones
        .find((z) => z._id === payload?.zoneId)
        .groups.find((g) => g._id === payload?.groupId);
      if (group) group.devices.push(payload.data);

      // state.zones = state.zones.map((zone) =>
      //   zone._id == payload?.zoneId
      //     ? {
      //         ...zone,
      //         groups: zone.groups.map((group) =>
      //           group._id == payload?.groupId
      //             ? {
      //                 ...group,
      //                 devices: group.devices.concat(payload.data),
      //               }
      //             : group,
      //         ),
      //       }
      //     : zone,
      // );
    },
    updateZone: (state, payload) => {
      const currentZone = state.zones.find(
        (z) => z._id === state.current.zoneId,
      );
      if (!currentZone) {
        return;
      }
      
      state.zones = state.zones.map((zone) =>
        zone._id === state.current.zoneId
          ? {
              ...zone,
              ...payload.data,
            }
          : zone,
      );
    },
    updateGroup: (state, payload) => {
      const currentZone = state.zones.find(
        (z) => z._id === state.current.zoneId,
      );
      let targetGroup = currentZone?.groups?.find(
        (group) => group._id === payload?.id,
      );
      if (!currentZone || !targetGroup) {
        return;
      }

      state.zones = state.zones.map((zone) =>
        zone._id === state.current.zoneId
          ? {
              ...zone,
              groups: zone.groups.map((group) =>
                group._id === payload?.id
                  ? {
                      ...group,
                      ...payload.data,
                    }
                  : group,
              ).sort((a, b) => {
                if (a.sort && !b.sort) return -1;
                if (!a.sort && b.sort) return 1;
                if (!a.sort && !b.sort) return 0;
                if (a.sort > b.sort) return 1;
                if (a.sort < b.sort) return -1;
                return 0;
              }),
            }
          : zone,
      );
    },
    updateDevice: (state, payload) => {
      const currentZone = state.zones.find(
        (z) => z._id == state.current.zoneId,
      );
      let targetGroup = currentZone?.groups?.find(
        (group) => group._id == payload?.groupId,
      );
      if (!currentZone || !targetGroup) {
        return;
      }

      state.zones = state.zones.map((zone) =>
        zone._id === currentZone._id
          ? {
              ...zone,
              groups: zone.groups.map((group) =>
                group._id === targetGroup._id
                  ? {
                      ...group,
                      devices: group.devices.map((device) =>
                        device._id === payload?.id
                          ? {
                              ...device,
                              ...payload.data,
                            }
                          : device,
                      ),
                    }
                  : group,
              ),
            }
          : zone,
      );
    },
    deleteZone: (state, payload) => {
      if (state.current.zoneId === payload) {
        state.current.zoneId = 0;
      }

      state.zones = state.zones.filter((item) => item._id !== payload);

      if (state.zones?.length > 0) {
        state.current.zoneId = state.zones[0]._id;
      }
    },
    deleteController:  (state, payload) => {
      state.zones = state.zones.map((zone) =>
        zone._id == payload.zoneId
          ? {
              ...zone,
              controllers: zone.controllers.filter(
                (controller) => controller._id != payload.controllerId,
              ),
            }
          : zone,
      );
    },
    deleteGroup: (state, payload) => {
      state.zones = state.zones.map((zone) =>
        zone._id == payload.zoneId
          ? {
              ...zone,
              groups: zone.groups.filter(
                (group) => group._id != payload.groupId,
              ),
            }
          : zone,
      );
    },
    deleteDevice: (state, payload) => {
      let group = state.zones
        .find((z) => z._id === payload?.zoneId)
        .groups.find((g) => g._id === payload?.groupId);
      let idx = group.devices.findIndex((gd) => gd._id === payload?.deviceId);
      if (idx >= 0) group.devices.splice(idx, 1);

      // state.zones = state.zones.map((zone) =>
      //   zone._id == payload.zoneId
      //     ? {
      //         ...zone,
      //         groups: zone.groups.map((group) =>
      //           group._id == payload.groupId
      //             ? {
      //                 ...group,
      //                 devices: group.devices.filter(
      //                   (device) => device._id != payload.deviceId,
      //                 ),
      //               }
      //             : group,
      //         ),
      //       }
      //     : zone,
      // );
    },

    changeDeviceSettingMode: (state, payload) => {
      let idx = state.settingModeDevices.findIndex(
        (d) => {
          if (d?.serial === payload?.serial &&
          d.type === payload.type &&
          ((d.type === DeviceType.Lamp && d.data?.port === payload.data.port && d.data?.port !== undefined && payload.data.port !== undefined) ||
            // d.data?.ports.every(
            //   (val, idx) => val === payload.data?.ports[idx],
            // ))
            (d.type > DeviceType.Lamp && d.data?.serial === payload.data?.serial && d.data?.serial !== undefined && payload.data?.serial !== undefined)))
            return d;
        }
      );

      if (payload.data.setting == SettingMode.InSettingMode) {
        if (idx < 0) {
          state.settingModeDevices.push(payload);
        }
      } else {
        if (idx >= 0) state.settingModeDevices.splice(idx, 1);
      }
    },
    removeSettingModeDevice: (state, payload) => {
      let idx = state.settingModeDevices.findIndex(
        (d) =>
          d?.serial === payload?.serial &&
          d.type === payload.type &&
          ((d.type === DeviceType.Lamp &&
            // d.data?.ports.every(
            //   (val, idx) => val === payload.data?.ports[idx],
            // ))
            d.data?.port === payload.data.port) ||
            (d.type > DeviceType.Lamp &&
              d.data?.serial === payload.data?.serial)),
      );

      if (idx >= 0) state.settingModeDevices.splice(idx, 1);
    },

    changeControllerFirmwareUpdateState: (state, payload) => {
      let controller = state.zones
        .find((z) => z.controllers.some((zc) => zc._id === payload?.serial))
        .controllers.find((zc) => zc._id === payload?.serial);
      controller.firmwareState = payload?.cmd;
    },

    updateControllerFirmwareVersion: (state, payload) => {
      let controller = state.zones
        .find((z) => z.controllers.some((zc) => zc._id === payload?.serial))
        .controllers.find((zc) => zc._id === payload?.serial);
      if (controller.firmwareState === MQTTCommand.FirmwareUpdateStarted && controller.version !== payload.version) {
        controller.firmwareState = MQTTCommand.FirmwareUpdateFinished;
      }
      controller.version = payload.version;
    },

    setFirmwares: (state, payload) => {
      state.firmwares = payload;
    },

    setProducts: (state, payload) => {
      state.products = payload;
    },

    setNotices: (state, payload) => {
      state.notices = payload;
    },

    setZoneEditMode: (state, payload) => {
      console.log(
        `home/mutations.setZoneEditMode => payload:${JSON.stringify(payload)}`,
      );

      const zone = state.zones.find((z) => z._id === payload.zoneId);
      if (zone) {
        zone.isEditMode = payload.isEditMode;

        // console.log(`home/mutations.setZoneEditMode => zone._id:${zone._id} , currentZoneId : ${state.current.zoneId}`)

        // if(state.current.zoneId === payload.zoneId){

        //   let idx = state.zones.findIndex((zz) => {
        //     return zz.isEditMode === false;
        //   });
        //   idx = idx < 0 ? 0 : idx;
        //   console.log('home/mutations.setZoneEditMode => ',idx)
        //   state.current.zoneId = state.zones[idx]._id
        // }
      }
    },

    initState: (state) => {
      state.current = {
        zoneId: 0,
        groupId: 0,
        deviceId: 0,
      };
      state.settingModeDevices = [];
      state.zones = [];
      state.firmwares = [];
      state.products = [];
    },
    
    setWebViewReady: (state, payload) => {
      state.isWebViewReady = payload;
    },

    updateCheckControllers: (state, payload) => {
      state.checkControllers[payload.serial] = payload.value || 0;
    }
  },
  actions: {
    fetchAllZones: async (store, payload) => {
      return new Promise(async (resolve, reject) => {
        Axios.get(ZoneApi.ZONES)
          .then((res) => {
            if (res.resultCode == '0000') {
              if (res.resultData && res.resultData.length > 0) {
                console.log(JSON.stringify(res.resultData))

                const sortedZones = res.resultData.slice().sort((a, b) => {
                  if (a.isOwner && !b.isOwner) return -1; // 이름 기준 오름차순
                  if (!a.isOwner && b.isOwner) return 1;
                  return 0; // 동일한 경우
                });

                console.log('sortedZones : ',sortedZones)

                store.commit('setZones', sortedZones);
                let idx = sortedZones.findIndex((zz) => {
                  return zz.isEditMode === false;
                });
                idx = idx < 0 ? 0 : idx;

                if (!store.state.current.zoneId || !sortedZones.find(zone => zone._id == store.state.current.zoneId)) {
                  store.commit('setCurrentZone', sortedZones[idx]._id);
                }
              } else {
                store.commit('initState');
              }
              return resolve(res.resultData);
            }
            return reject(-1);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
    fetchFirmwares: async (store, payload) => {
      try {
        const result = await Axios.get(FirmwareApi.FIRMWARES);
        if (result.resultCode === '0000') {
          // console.log(JSON.stringify(result.resultData))
          store.commit('setFirmwares', result.resultData);
        } else {
          console.warn(result.resultMessage);
        }
      } catch (error) {
        console.log(error);
      }
    },
    fetchProducts: async (store, payload) => {
      try {
        const result = await Axios.get(ProductApi.PRODUCTS);
        if (result.resultCode === '0000') {
          // console.log(JSON.stringify(result.resultData))
          store.commit('setProducts', result.resultData);
        } else {
          console.warn(result.resultMessage);
        }
      } catch (error) {
        console.log(error);
      }
    },
    fetchNotices: async (store, payload) => {
      try {
        const result = await Axios.get(NoticeApi.NOTICES);
        if (result.resultCode === '0000') {
          // console.log(result.resultData)
          const sortedResultData = result.resultData.sort((a, b) => a._id > b._id ? -1 : (a._id < b._id ? 1 : 0));
          store.commit('setNotices', sortedResultData.filter(item => item.isPublic == true));
        } else {
          console.warn(result.resultMessage);
        }
      } catch (error) {
        console.log(error);
      }
    },

    // payload : {controllerId : , type : , item : {}}
    // 현재 조명에 대한 처리만 가능함, 아직 커튼 및 보일러등에 대한 정의는 없음.
    changeDeviceState: async (store, payload) => {
      console.log('home/changeDeviceState => payload : ', payload);

      var ports = payload.data.port != undefined 
        ? (Array.isArray(payload.data.port) ? payload.data.port : [payload.data.port]) 
        : payload.data.ports;

      // const arrayEquals = (a,b) =>{
      //   return Array.isArray(a) &&
      //   Array.isArray(b) &&
      //   a.length === b.length &&
      //   a.every((val, index) => val === b[index])
      // }

      // var device = store.state.zones
      //   .find((z) =>
      //     z.groups.some((g) =>
      //       g.devices.some((d) => d.controllerId === payload.serial),
      //     ),
      //   )
      //   .groups.find((g) =>
      //     g.devices.some(
      //       (d) =>
      //         d.controllerId === payload.serial &&
      //         d.type === payload.type &&
      //         ((d.type === DeviceType.Lamp && d.item.ports.includes(ports)) ||
      //           (d.type > DeviceType.Lamp &&
      //             d.item.serial === payload.data.serial)),
      //     ),
      //   )
      //   .devices.find(
      //     (d) =>
      //       d.controllerId === payload.serial &&
      //       d.type === payload.type &&
      //       ((d.type === DeviceType.Lamp && d.item.ports.includes(ports)) ||
      //         (d.type > DeviceType.Lamp &&
      //           d.item.serial === payload.data.serial)),
      //   );

      const devices = [];
      store.state.zones.find((z) =>
        z.groups.some((g) =>
          g.devices.some((d) => d.controllerId === payload.serial),
        )
      ).groups.filter((g) =>
        g.devices.some(
          (d) =>
            d.controllerId === payload.serial &&
            d.type == payload.type &&
            ((d.type == DeviceType.Lamp && d.item?.ports?.some(port => ports.includes(port))) 
            || (d.type > DeviceType.Lamp &&
                d.item?.serial === payload.data.serial)),
        )
      ).forEach((g) => {
        g.devices.forEach((d) => {
          if (d.controllerId === payload.serial && d.type === payload.type && 
            ((d.type == DeviceType.Lamp && d.item?.ports?.some(port => ports.includes(port))) 
            || (d.type > DeviceType.Lamp && d.item?.serial === payload.data.serial))) {
                devices.push(d);
          }
        })
      })

      devices.forEach(device => {
        console.log('call store/home.actions.changeDeviceState() => commit :', device);
        
        const values = {
          onoff: payload.data.onoff,
          bright: payload.data.bright,
          color: payload.data.color,
        };

        store.commit('setDeviceState', {
          deviceId: device._id,
          type: payload.type,
          state: values
        });
      })
    },

    chanageTempDeviceState: async (store, payload) => {
      console.log('home/chanageTempDeviceState => payload : ', payload);
      
      let state = undefined;
      let targetType = undefined;
      switch(payload.command) {
        case MQTTTempCommand.TempTargetSet:
          state = { targetTemp: parseFloat(payload.value.TempTargetSet) };
          targetType = DeviceType.Boiler;
          break;
        case MQTTTempCommand.RoomTempReal:
          state = { currentTemp: parseFloat(payload.value.Temperature) };
          targetType = DeviceType.Boiler;
          break;
        case MQTTTempCommand.AwayModeSet:
          state = { onoff: parseInt(payload.value.AwayModeSet == 0 ? 1 : 0) };
          targetType = DeviceType.Boiler;
          break;
        case MQTTTempCommand.PatternModeSet:
          state = { pattern: parseInt(payload.value.PatternModeSet) };
          targetType = DeviceType.Boiler;
          break;
        case MQTTTempCommand.PatternMode2xSet:
          state = { pattern2x: parseInt(payload.value.PatternMode2XSet) };
          targetType = DeviceType.Boiler;
          break;
        case MQTTTempCommand.TimeOnCycleSet:
          state = { customPattern: { on: payload.value.TimeOnCycleSet1 } };
          targetType = DeviceType.Boiler;
          break;
        case MQTTTempCommand.TimeOffCycleSet:
          state = { customPattern: { off: payload.value.TimeOffCycleSet } };
          targetType = DeviceType.Boiler;
          break;

        // case MQTTCurtainCommand.IsScanCount:
        //   state = { customPattern: { off: payload.value.ISScanCount.Data } };
        //   break;

        case MQTTCurtainCommand.IsOpen:
          if (payload.value.ISOpen == "Error") {
            return;
          }
          console.log(payload.value)
          state = { close: 0 };
          targetType = DeviceType.Curtain;
          break;
        case MQTTCurtainCommand.IsClose:
          if (payload.value.ISClose == "Error") {
            return;
          }
          console.log(payload.value)
          state = { close: 1 };
          targetType = DeviceType.Curtain;
          break;
        case MQTTCurtainCommand.IsStop:
          if (payload.value.ISStop == "Error") {
            return;
          }
          console.log(payload.value)
          // state = { close: 0 };
          targetType = DeviceType.Curtain;
          break;
        case MQTTCurtainCommand.IsSetClosePercent:
          if (payload.value.ISSetClosePercent == "Error") {
            return;
          }
          console.log(payload.value)
          state = { percent: payload.value.ISSetClosePercent };
          targetType = DeviceType.Curtain;
          break;
        case MQTTCurtainCommand.IsReadPercent:
          break;
        case MQTTCurtainCommand.RS485AddrWrite:
          if (payload.value.RS485AddrWrite == "Error") {
            return;
          }
          console.log(payload.value)
          state = { address: payload.value.RS485AddrWrite };
          targetType = DeviceType.Curtain;
          break;
        case MQTTCurtainCommand.RS485Addr:
          break;
        case MQTTCurtainCommand.IsWriteAddress:
          break;
        case MQTTCurtainCommand.IsWriteDirection:
          if (payload.value.ISWriteDirection == "Error") {
            return;
          }
          console.log(payload.value)
          state = { reverse: payload.value };
          targetType = DeviceType.Curtain;
          break;
      }

      if (!state) {
        return;
      }

      const devices = [];
      store.state.zones.find((z) =>
        z.groups.some((g) =>
          g.devices.some((d) => d.controllerId === payload.controllerId),
        )
      )?.groups?.filter((g) =>
        g.devices.some(
          (d) => d.controllerId === payload.controllerId && d.type == targetType
        )
      )?.forEach((g) => {
        g.devices.forEach((d) => {
          if (d.controllerId === payload.controllerId && d.type === targetType) {
            devices.push(d);
          }
        })
      });

      if (devices.length === 0) {
        return;
      }

      console.log(state);
      devices.forEach(device => {
        store.commit('setDeviceState', {
          deviceId: device._id,
          type: device.type,
          state
        });
      });
    },

    updateDeviceState: async (store, payload) => {
      // console.log('call store/home.actions.updateDeviceState() => payload : ',payload)
      store.commit('setDeviceState', {
        deviceId: payload.deviceId,
        type: payload.type,
        state: payload.data,
      });
    },

    updateGroupDeviceState: async (store, payload) => {
      // console.log('call store/home.actions.updateGroupDeviceState() => payload : ',payload)
      store.commit('setDeviceState', {
        deviceId: payload.deviceId,
        type: payload.type,
        state: payload.data,
      });
    },
    changeDeviceSettingMode: async (store, payload) => {
      store.commit('changeDeviceSettingMode', payload);
    },
    removeSettingModeDevice: async (store, payload) => {
      store.commit('removeSettingModeDevice', payload);
    },

    changeControllerFirmwareUpdateState: async (store, payload) => {
      store.commit('changeControllerFirmwareUpdateState', payload);
    },

    changeZoneEditMode: async (store, payload) => {
      console.log(
        'home/actions.changeZoneEditMode => payload:',
        JSON.stringify(payload),
      );
      store.commit('setZoneEditMode', payload);
    },

    updateControllerFirmwareVersion: async (store, payload) => {
      store.commit('updateControllerFirmwareVersion', payload);
    },

    updateCheckControllers: async (store, payload) => {
      store.commit('updateCheckControllers', payload);
    },
  },
};
