<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">온도조절기 관리</h4>
    <p>
      방 목록을 선택하면 온도조절기 관리 화면으로 이동할 수 있습니다.
    </p>

    <hr class="mb-4" v-if="boilerDivices.length > 0" />

    <div class="row" v-for="item in boilerDivices" :key="item._id">
      <div class="col-md-6 mb-2">
        <div class="list-group" style="border-radius: 1.2rem">
          <ListGroupItem>
            <div class="flex-1 text-break" v-touch:hold="onTouchHold">
              <div style="font-size: 0.9rem">{{ item.group.name }}: {{ item.name }}</div>
            </div>
            <div @click.stop="() => onDeleteClick(item)" style="text-align: right; min-width: 40px;">
              <i class="fa fa-x"></i>
            </div>
          </ListGroupItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute ,onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
import PopDialog from '@/common/components/popup/PopDialog.vue';
import useModal from '@/common/hooks/useModal';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';
import { Template } from '@/common/constants';
import useMqttControl from '@/common/hooks/useMqttControl';
import Axios, { DeviceApi, CtrlApi } from '@/api';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const router = useRouter();
const route = useRoute();
const store = useStore();
const zone = computed(() => store.getters['home/getCurrentZone']);
const groups = computed(() => zone.value?.groups || []);
const boilerDivices = computed(() => {
  let devices = [];
  groups.value.forEach(group => {
    const resultDevices = group.devices.filter(device => device.type == 2)
      .map(device => ({ group, ...device }));
    devices = [...devices, ...resultDevices];
  });
  return devices;
});
const curtainDivices = computed(() => {
  let devices = [];
  groups.value.forEach(group => {
    const resultDevices = group.devices.filter(device => device.type == 3)
      .map(device => ({ group, ...device }));
    devices = [...devices, ...resultDevices];
  });
  return devices;
});

const requestDeleteDevice = async (device) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.delete(
        DeviceApi.DELETE_DEVICE.replace(':id', device._id),
      );
      if (result.resultCode === '0000') {
        store.commit('home/deleteDevice', {
          zoneId: parseInt(zone.value._id),
          groupId: parseInt(device.group._id),
          deviceId: device._id,
        });
        resolve(true);
        return;
      }
      reject("장치 삭제에 실패했습니다.");
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

const requestDeleteController = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.delete(
        CtrlApi.DELETE_CTRL.replace(':id', id),
      );
      if (result.resultCode === '0000') {
        store.commit('home/deleteController', {
          zoneId: zone.value._id,
          controllerId: id,
        });
        resolve(true);
        return;
      }
      reject("컨트롤러 삭제에 실패했습니다.");
    } catch (error) {
      console.log(error)
      reject(error);
    }
  });
};

const onDeleteClick = (boiler) => {
  const curtains = curtainDivices.value.filter((curtainDevice) => curtainDevice.controllerId == boiler.controllerId);
  const deleteDevices = curtains.length > 0 ? [boiler, ...curtains] : [boiler];
  const description = curtains.length > 0 ? "커튼이 포함된 장치입니다.\n커튼을 포함하여 전부 삭제하시겠습니까?" : "정말 삭제하시겠습니까?";

  emitter.emit('showPopConfirm', {
    description,
    okCallback: async () => {
      try {
        deleteDevices.forEach(async device => await requestDeleteDevice(device));
        await requestDeleteController(boiler.controllerId);

        emitter.emit('showPopAlert', {
          description: "온도조절기가 삭제되었습니다.",
        });
      } catch (e) {
        emitter.emit('showPopAlert', {
          description: e,
        });
      }
    }
  });
}
</script>
