<template>
  <WizardConnectStep 
    v-if="wizardStep === 1" 
    :step="wizardStep"
    title="컨트롤러 연결 (와이파이 변경)"
    :warningMessages="['기존에 연결된 컨트롤러의 wifi 셋팅 정보를 변경할때만 사용하십시오.']"
  />
  <WizardSettingStep
    v-if="wizardStep === 2"
    :step="wizardStep"
    :controller="connectedController"
    :ssid="settingParams.ssid"
    :password="settingParams.pwd"
    :handleSsidChange="onSsidChange"
    :handlePasswordChange="onPasswordChange"
  />
  <WizardSetWifiCompleteStep
    v-if="wizardStep === 3"
    :step="wizardStep"
    :controller="connectedController"
  />

  <div id="footer" class="app-footer ms-0">
    <footer class="mt-auto">
      <div class="row">
        <div class="col" />
        <div class="col" />
        <div class="col">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            style="width: 100%"
            @click="onNextStepClick"
          >
            {{ wizardStep === 1 ? '연결' : wizardStep === 2 ? '설정' : '완료' }}
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useMobile from '@/common/hooks/useMobile';
import WizardConnectStep from './components/wizard/WizardConnectStep';
import WizardSettingStep from './components/wizard/WizardSettingStep';
import WizardSetWifiCompleteStep from './components/wizard/WizardSetWifiCompleteStep';
import { SettingConfig } from '@/common/constants';

const store = useStore();
const router = useRouter();
const {
  getWifiInfo,
  connectWifi,
  settingController,
  disConnectWifi
} = useMobile();
const { emitter, mobile, logger } =
  getCurrentInstance().appContext.config.globalProperties;

const settingParams = ref({
  ssid: '',
  pwd: '',
  firmware_version: '',
  firmware_url: '',
  uuid: '',
  device_key: '',
  mqtt_url: process.env.VUE_APP_CTRL_MQTT_DOMAIN,
  mqtt_username: '',
  mqtt_password: '',
});

const connectedController = ref({
  _id: '',
  version: '',
  model: '',
});
const wizardStep = ref(1);

onBeforeUnmount(() => {
  disConnectWifi();
});

const showPopAlert = (msg) => {
  emitter.emit('showPopAlert', {
    description: msg,
  });
};

const onSsidChange = (value) => {
  settingParams.value.ssid = value;
};

const onPasswordChange = (value) => {
  settingParams.value.pwd = value;
};

const onNextStepClick = async () => {
  try {
    if (wizardStep.value === 1) {
      const currentWifi = await getWifiInfo();
      // settingParams.value.ssid = currentWifi.ssid;
      const controllerInfo = await connectWifi();
      connectedController.value.model = controllerInfo.model;
      connectedController.value._id = controllerInfo.serial_no;
      connectedController.value.version = controllerInfo.version;
    } else if (wizardStep.value === 2) {
      await settingController(
        settingParams.value,
        connectedController.value._id,
      );
      // await store.dispatch('home/fetchAllZones');
    } else if (wizardStep.value === 3) {
      router.go(-1);
      return;
    }
    wizardStep.value += 1;
  } catch (err) {
    if (err?.length > 0) {
      showPopAlert(err);
    }
  }
};
</script>
